import { Component } from '@angular/core';
import { Constants } from 'src/app/constants';
import { GeneralConfirmationService } from './general-confirmation.service';

@Component({
  selector: 'app-general-confirmation-popup',
  templateUrl: './general-confirmation-popup.component.html',
  styleUrls: ['./general-confirmation-popup.component.scss']
})
export class GeneralConfirmationPopupComponent {

  constructor(public readonly generalConfirmationService: GeneralConfirmationService){}

  yes(){
    this.generalConfirmationService.ConfirmEvent.next(Constants.YES);
    this.generalConfirmationService.askConfirmation = false;
  }
  
  dismiss(){
    this.generalConfirmationService.Close.next(Constants.CLOSE);
    this.generalConfirmationService.askConfirmation = false;
  }

}
