import { Environment } from 'src/app/models/Environment';

export const environment: Environment = {
  apiBaseUrl: 'https://cs-slp-backend-dev.azurewebsites.net/',
  homePageUrl: 'https://slpdev.cs.ms.hcvpc.io/',
  auth0Client: {
    clientId: '079e54ba-8deb-4888-8356-d7f8ca2fd2a6',
    authority: 'https://login.microsoftonline.com/5dbf1add-202a-4b8d-815b-bf0fb024e033',
    redirectUri: 'https://slpdev.cs.ms.hcvpc.io/',
    postLogoutRedirectUri: 'https://slpdev.cs.ms.hcvpc.io/',
    scope: 'https://graph.microsoft.com/.default'
  },
  enableProdMode: false,
  envName: 'dev',
  production: false,
  enableAuth: true,
};
