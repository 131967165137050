import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { SqlServiceService } from 'src/app/core/sql-service.service';
import { RoleService } from 'src/app/services/role.service';
import { SearchBarComponent } from 'src/app/shared/search-bar/search-bar.component';
import { SlpDetailsCard, BaseSystemDataCard, FunctionalGroup, FunctionalGroupDataCard, FunctionalGroupData } from 'src/app/models';


@Component({
  selector: 'app-base-systems-form',
  templateUrl: './base-systems-form2.component.html',
  styleUrls: ['./base-systems-form2.component.scss']
})
export class BaseSystemsFormComponent {
  @Input() slpDetailsCardData: SlpDetailsCard;
  @Input() isreferenceSystemDisplay:boolean;  
  @Input() selectedBaseSystemDetailsDataArray: BaseSystemDataCard[];
  @Input() savedSelectedBaseSystemCardId: number;
  @Input() savedSelectedFGs: FunctionalGroup[]
  @Input() isreloadclicked: boolean;
  @Input() isSLPID: number;
  @Input() savedFgDataCache: Map<string, any[]>;
  @Input() savedSumIBDataCache: Map<string, number>;
  @Input() savedFgInfoMap: Map<number, FunctionalGroupData[]>;
  @Input() savedSelectedFGCardData: { cardId: number, name: string, IVK: string } = { cardId: -1, name: '', IVK: '' };
  @Output() isReferenceSystemSelected = new EventEmitter<boolean>();
  @ViewChild(SearchBarComponent) searchBarComponent: SearchBarComponent;

  isReadOnly: boolean = false;
  isLoading: boolean = false;
  selectedBaseSystemCardId: number = -1;
  FGLoadingSpinner: boolean = false
  slpHourRate: number = 0
  existingSlpID: number = -1;
  totaltotalcost: number = 0;
  sumIB: number = 0;
  fgDataCache = new Map<string, any[]>();
  sumIBDataCache = new Map<string, number>();
  tempFGArray: any[] = [];
  slpName: string;
  recentlySavedSlpId: number;
  slpYears: string;
  reloadClicked: boolean = false;
  slpNotifications: string;
  sumSPQuantity: number = 0;
  sumConfirmTravelShare: number = 0;
  sumSPPriceQty: number = 0;
  sumConfirmWorkShare: number = 0;
  FGArray: any[] = [];
  filteredFGArray: any[] = [];
  selectedSPCount = 0
  selectedFGs: FunctionalGroup[] = [];
  selectedFGPartsCardsId: number = -1;
  costpersystem: number = 0;
  fgInfoMap: Map<number, FunctionalGroupData[]> = new Map<number, FunctionalGroupData[]>();
  referenceSystemData: { [key: string]: FunctionalGroupData[] } = {};

  constructor(
    private sqlServiceService: SqlServiceService,
    private roleService: RoleService
  ) { 
    this.isreferenceSystemDisplay = false;
  }

  ngOnInit(): void {
    this.isreferenceSystemDisplay = true;
    this.setPermissions();
    this.setSavedData();
    this.loadBaseSystemData();
    this.setSelectedFGPartsCardData();
    this.getSavedCheckboxandSelectedPartsCalculationData();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.isreferenceSystemDisplay = false;
  }

  setPermissions() {
    this.isReadOnly = !this.roleService.isDeveloperOrProjectManager();
  }

  setSavedData() {
    this.slpHourRate = this.slpDetailsCardData.hourRate;
    this.slpName = this.slpDetailsCardData.projectName;
    this.slpYears = this.slpDetailsCardData.years;
    this.slpNotifications = this.transformNotificationString(this.slpDetailsCardData.notifications);
    this.selectedFGs = this.savedSelectedFGs
    this.reloadClicked = this.isreloadclicked
    this.existingSlpID = this.isSLPID;
    this.fgDataCache = this.savedFgDataCache;
    this.sumIBDataCache = this.savedSumIBDataCache;
  }

  async loadBaseSystemData() {
    this.isLoading = true;

    for (const baseSystemData of this.selectedBaseSystemDetailsDataArray) {
      const matid = baseSystemData.MATID;
      const id = baseSystemData.id;
      this.tempFGArray = [];
      this.totaltotalcost = 0;
    
      try {
        await this.setSumIbData(matid);
        await this.executeBaseSystemDataApiCall(matid, id);
      } catch (error) {
        this.isLoading = false;
      }
    }
    this.isLoading = false;
    if (this.selectedBaseSystemDetailsDataArray.length > 0) {
      this.selectedBaseSystemCardId = 0;
      this.toggleClick(this.selectedBaseSystemCardId)
      const matid = this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId].MATID;
      this.FGArray = this.fgDataCache.get(matid) ?? [];
      this.filteredFGArray = this.FGArray;
    }
  }

  setSelectedFGPartsCardData() {
    if (this.savedSelectedBaseSystemCardId !== -1) {
      this.selectedBaseSystemCardId = this.savedSelectedBaseSystemCardId;
      this.calculateSparePartTableData({ matid: this.selectedBaseSystemDetailsDataArray[this.savedSelectedBaseSystemCardId].MATID, id: this.savedSelectedBaseSystemCardId });
    }
    this.selectedFGPartsCardsId = this.savedSelectedFGCardData.cardId;
  }

  async getSavedCheckboxandSelectedPartsCalculationData() {
    this.isReferenceSystemSelected.emit(false);
    if (this.reloadClicked || this.existingSlpID !== 0) {
      this.selectedFGs = await lastValueFrom(this.sqlServiceService.getCheckedDataForSLP(this.existingSlpID));
      this.referenceSystemData = await lastValueFrom(this.sqlServiceService.FetchReferenceSystemStoredDataForSLP(this.selectedFGs, this.isSLPID));
    }
    if (this.selectedFGs.length === 0) {
      this.selectedFGPartsCardsId = -1

    } else {
      this.isReferenceSystemSelected.emit(true);
    }
  }

  transformNotificationString(input: string): string {
    return input
      .split(',')
      .map(item => item.trim())
      .map(item => item.split(' ')[0])
      .join(',');
  }

  toggleClick(cardId: number): void {
    if (this.selectedBaseSystemCardId !== -1) {
      this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId]["isSelected"] = false;
    }
    this.selectedBaseSystemCardId = cardId;
    this.selectedBaseSystemDetailsDataArray[cardId]["isSelected"] = true;
    this.searchBarComponent?.clearSearch(); 
  }

  updateSelectedBaseSystemCardCost(cardId: number): void {
    this.selectedBaseSystemDetailsDataArray[cardId]["costPerSystem"] = this.costpersystem;
  }

  async processServicePartsData(servicePartsDataArray: any, matid: string, id: number, includeNoPartCalculations: boolean = false) {
    this.tempFGArray = this.tempFGArray.concat(servicePartsDataArray.AGGREGATEDRESULTS);
    this.totaltotalcost += servicePartsDataArray.TOTALCOSTSUM;

    if (includeNoPartCalculations) {
      await this.NoPartCalculation(matid, "No_Part");
      await this.NoPartCalculation(matid, "No_Part_or_Travel");
    } else {
      await this.NoPartNoTravelCalculation(matid);
    }
    await this.OthersCalculation(matid);

    this.costpersystem = this.sumIB > 0 ? parseFloat((this.totaltotalcost / this.sumIB).toFixed(2)) : 0;
    this.updateSelectedBaseSystemCardCost(id);
    this.fgDataCache.set(matid, this.tempFGArray);
    this.FGArray = this.tempFGArray;
    this.filteredFGArray = this.FGArray;
  }

 async executeBaseSystemDataApiCall(matid: string, id: number) {
  if(this.reloadClicked && this.existingSlpID !== 0) {
    this.fgDataCache.clear();
  }
    if(this.fgDataCache.has(matid)) {
      this.FGArray = this.fgDataCache.get(matid) ?? [];
      this.filteredFGArray = this.FGArray;
    } else {
      if (this.existingSlpID === 0) {
        this.recentlySavedSlpId = await lastValueFrom(this.sqlServiceService.getRecentSavedSlpProjectId()) ?? -1;
        const servicePartsDataArray = await lastValueFrom(this.sqlServiceService.getServicePartData(matid, this.recentlySavedSlpId, this.slpHourRate, matid, this.slpYears, this.slpNotifications));
        await this.processServicePartsData(servicePartsDataArray, matid, id, true);
      } else {
          const servicePartsDataArray = await lastValueFrom(this.sqlServiceService.getStoredProcedureFGdata(this.existingSlpID, matid));
          await this.processServicePartsData(servicePartsDataArray, matid, id);
      }
    }
  }

  async setSumIbData(matid: string) { 
    if(this.reloadClicked && this.existingSlpID !== 0) {
      this.sumIBDataCache.clear();
    }

    if(this.sumIBDataCache.has(matid)) {
      this.sumIB = this.sumIBDataCache.get(matid) ?? 0;
    } else {
      this.sumIB = await lastValueFrom(this.sqlServiceService.getSumIB(matid));
      this.sumIBDataCache.set(matid, this.sumIB);
    }
  }

  async calculateSparePartTableData(selectedCardData: { matid: string, id: number }) {
    this.FGLoadingSpinner = true
    this.tempFGArray = [];
    this.FGArray = [];
    this.filteredFGArray = this.FGArray;
    const { matid, id } = selectedCardData

    // Calculate logic
    this.toggleClick(id);
    this.totaltotalcost = 0;

    await this.setSumIbData(matid);
    await this.executeBaseSystemDataApiCall(matid, id);
    
    this.FGLoadingSpinner = false;
  }

  async NoPartCalculation(matid: string, name: string) {
    this.sumSPQuantity = 0;
    this.sumConfirmTravelShare = 0;
    this.sumSPPriceQty = 0;
    this.sumConfirmWorkShare = 0;

    const noPartsDataArray = await this.sqlServiceService.getServicePartData2(matid, name).toPromise();
    if (noPartsDataArray.length > 0) {
      this.sumSPQuantity = noPartsDataArray[0].TOTALSPQUANTITY;
      this.sumConfirmTravelShare = noPartsDataArray[0].TOTALSPCONFIRMTRAVELSHARE;
      this.sumConfirmWorkShare = noPartsDataArray[0].TOTALSPCONFIRMWORKSHARE;
      this.sumSPPriceQty = noPartsDataArray[0].TOTALSPPRICEQTY;
      const item: FunctionalGroup = {
        ID: "1",
        FG: name,
        IVK: "0",
        MATERIAL: '-',
        CREATEDDATE: "0001-01-01T00:00:00",
        CREATEDBY: "",
        SYSMATID: matid,
        SPC: this.sumSPQuantity,
        WORK: parseFloat(((this.sumConfirmWorkShare / this.sumSPQuantity) * this.slpHourRate).toFixed(2)),
        TRAVEL: parseFloat(((this.sumConfirmTravelShare / this.sumSPQuantity) * this.slpHourRate).toFixed(2)),
        PRICE: parseFloat((this.sumSPPriceQty / this.sumSPQuantity).toFixed(2)),
        MTBF: parseFloat((this.sumIB / this.sumSPQuantity).toFixed(2)),// fetch sum(ib) from system material monthly table
        INFLUENCE: 0, // calculate percentage of this row share from total cost share
        TOTALCOST: parseFloat(((this.sumConfirmWorkShare * this.slpHourRate) + (this.sumConfirmTravelShare * this.slpHourRate) + this.sumSPPriceQty).toFixed(2)),
        CHECKED: noPartsDataArray[0].CHECKED === 1 ? true : false
      };
      this.totaltotalcost += item.TOTALCOST;
      this.tempFGArray.push(item);
    }
  }

  async OthersCalculation(matid: string) {
    let otherPartsDataArray: any = [];
    if (this.existingSlpID === 0) {
      otherPartsDataArray = await lastValueFrom(this.sqlServiceService.getServicePartOthers(matid, this.recentlySavedSlpId, this.slpHourRate, matid, this.slpYears, this.slpNotifications));   
    }
    else {
      // when reload is clicked or when slpID is not 0
      otherPartsDataArray = await lastValueFrom(this.sqlServiceService.getStoredProcedureOthersData(this.existingSlpID, matid));
    }
    this.tempFGArray = this.tempFGArray.concat(otherPartsDataArray.AGGREGATEDRESULTS);
    this.totaltotalcost += otherPartsDataArray.TOTALCOSTSUM;
  }

  async NoPartNoTravelCalculation(matid: string) {
    let noPartsDataArray: any = [];
    noPartsDataArray = await lastValueFrom(this.sqlServiceService.getStoredProcedureNoPartData(this.existingSlpID, matid));
    this.tempFGArray = this.tempFGArray.concat(noPartsDataArray);
    if (noPartsDataArray.length > 1) {
      this.totaltotalcost += noPartsDataArray[0].TOTALCOST;
      this.totaltotalcost += noPartsDataArray[1].TOTALCOST;
    }
  }

  // Handling Table Selection and Dselection
  isSameAsPrevious(index: number): boolean {
    if (index === 0) return false;
    return this.FGArray[index].FG === this.FGArray[index - 1].FG;
  }

  handleFGCheckboxSelection(fgData: FunctionalGroup) {
    if (this.isFGSelected(fgData.FG)) {
      this.deselectFG(fgData.FG);
    }
    else {
      this.selectFG(fgData.FG);
    }
  }
  
  isFGSelected(fgName: string): boolean {
    return this.FGArray.some(FGData =>
      FGData.FG === fgName && FGData.CHECKED === true
    );
  }

  deselectFG(fgName: string) {
    const matId = this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId].MATID;
    this.selectedFGs = this.selectedFGs.filter(obj => !(obj.FG === fgName && obj.SYSMATID === matId));
    this.FGArray.forEach((item) => {
      if (item.FG === fgName) {
        item.CHECKED = false;
      }
    });
    this.fgDataCache.set(matId, this.FGArray);
    if (this.selectedFGs.length === 0 || this.savedSelectedFGCardData.name === fgName) {
      this.selectedFGPartsCardsId = -1
    }
    if (this.selectedFGs.length === 0) {
      this.isReferenceSystemSelected.emit(false);
    }
  }

  selectFG(fgName: string) {
    const matId = this.selectedBaseSystemDetailsDataArray[this.selectedBaseSystemCardId].MATID;
    this.FGArray.forEach((item) => {
      if (item.FG === fgName) {
        item.CHECKED = true;
        this.selectedFGs.push(item);
        this.isReferenceSystemSelected.emit(true);
      }
    });
    this.fgDataCache.set(matId, this.FGArray);
  }

  // Method to handle SP checkbox selection
  handleSPCheckboxSelection(obj: FunctionalGroup) {
    if (obj.CHECKED === true) {
      this.deselectSP(obj.IVK);
    } else {
      this.selectedFGs.push(obj);
      this.isReferenceSystemSelected.emit(true);
    }
    obj.CHECKED = !obj.CHECKED;
  }

  // Method to deselect a row ID
  deselectSP(fgIVK: string) {
    this.selectedFGs = this.selectedFGs.filter(obj => obj.IVK !== fgIVK);
    if (this.selectedFGs.length === 0 || this.savedSelectedFGCardData.IVK === fgIVK) {
      this.selectedFGPartsCardsId = -1
      this.isReferenceSystemSelected.emit(false);
    }
  }

  handleReferenceSystemData(index: number, fgName: string) {
    if (this.reloadClicked || this.existingSlpID !== 0) {
      this.savedFgInfoMap.set(index, this.referenceSystemData[fgName.toUpperCase()] ?? []);
    }
  }

  getUniqueFGValues() {
    let updateSelectedFGArray: FunctionalGroupDataCard[] = []
    const seen = new Set();
    const filteredSampleArray = this.selectedFGs.filter((item: any) => {
      const val = item['FG']
      if (seen.has(val)) {
        return false;
      } else {
        seen.add(val);
        return true;
      }
    });
    filteredSampleArray.forEach((selectedSparePartsData, index) => {
      updateSelectedFGArray.push({ ...selectedSparePartsData, "isSelected": false, "cardId": index, "TARGETCOST": 0, "REFCOST": 0 })
      this.handleReferenceSystemData(index, selectedSparePartsData.FG)
      const savedFgInfo = this.savedFgInfoMap.get(index);
      this.fgInfoMap.set(index, savedFgInfo ?? []);
    })
    return updateSelectedFGArray
  }

  getAllFGValues() {
    let updateSelectedFGArray: FunctionalGroupDataCard[] = []
    this.selectedFGs.forEach((selectedSparePartsData, index) => {
      updateSelectedFGArray.push({ ...selectedSparePartsData, "isSelected": false, "cardId": index, "TARGETCOST": 0, "REFCOST": 0 })
    })
    return updateSelectedFGArray
  }

  handleSearchResults(results: any[]): void {
    this.filteredFGArray = results;
  }
}