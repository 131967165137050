import { Component, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';
import { SlpProjectData } from 'src/app/models';

@Component({
  selector: 'app-all-slp',
  templateUrl: './all-slp.component.html',
  styleUrls: ['./all-slp.component.scss']
})

export class AllSlpComponent {
  public readonly constants = Constants;
  private _allSlpData: SlpProjectData[] = [];
  filteredAllSlpData: SlpProjectData[] = [];

  @Input()
  set allSlpData(data: SlpProjectData[]) {
    this._allSlpData = data;
    this.filteredAllSlpData = [...this._allSlpData];
  }
  get allSlpData(): SlpProjectData[] {
    return this._allSlpData;
  }
  @Input() isLoading = true;
  @Output() updateSubscribedEvents = new EventEmitter<number>();
  @Output() updateAllSlpProjectAfterDelete = new EventEmitter<number>();

  constructor(
    private router: Router
  ) { }

  handlingSubscribed(slpID: number) {
    this.updateSubscribedEvents.emit(slpID)
  }

  onViewAllSlpCardClick(selectedSlpData: SlpProjectData) {
    this.router.navigate(['information'], { state: { savedSlpFormData: selectedSlpData } });
  }

  async handlingUpdateAllSlpProjectAfterDelete(slpId: number) {
    this.updateAllSlpProjectAfterDelete.emit(slpId);
  }

  handleSearchResults(results: any[]): void {
    this.filteredAllSlpData = results;
  }
}
