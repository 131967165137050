export class Constants {
    static readonly LOCAL_STORAGE_KEYS = {
        LOGGED_IN_USER_ROLES : 'LoggedInUserRoles'
    }
    static readonly DEFAULT_PAGE_SIZE = 200;
    static readonly YES_NO_FLAG = {
        YES : {
            key : 'Yes',
            value: 1
        },
        NO : {
            key : 'No',
            value: 0
        }
    };
    static readonly ALL_SLP_DATA_SEARCH_KEYS = ['SLPNAME', 'BUISNESSLINE', 'CSPROJECTMANAGER', 'STATUS', 'DUEDATE'];

    static readonly ROLES = {
        AT: "AT",
        CT: "CT",
        MR: "MR",
        XP: "XP",
        DNA: "DNA",
        DEVELOPER:'DEVELOPER',
        PROJECTMANAGER:'PROJECT MANAGER'
    }; 

    static readonly REGEX_VALIDATION_PATTERN = {
        YEAR: /^\d{4}$/,
        POSITIVE_DECIMAL_OR_INTEGER:/^(?:[1-9]\d*|0)?(?:\.\d+)?$/,
        NEGATIVE_POSITIVE_DECIMAL_OR_INTEGER:/^-?(0|[1-9]\d*|[1-9]\d*\.\d+|\.\d+)$/
    }

    static readonly INPUT_TYPES = {
        DECIMAL_INPUT: ['.', 'e', ',', '-', '+'],
        POSITIVE_INPUT: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'],
        POSITIVE_NEGATIVE_INPUT: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.'],
        SPECIAL_INPUT: ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'],
    }

    static readonly PROJECT_STATUS = {
        IN_WORK: "In Work",
        RELEASED: "Released",
        COMPLETED: "Completed",
        DELAYED: "Delayed",
    }
    
    static readonly YES='Yes';
    static readonly NO='No';
    static readonly CLOSE='Close';

    static readonly SEARCH_URL='/home/search';
    static readonly SEARCH_URL_DETAIL='/home/search/details';

    static readonly MATERIAL_MASTER_URL='/home/materialmaster';
    static readonly MATERIAL_MASTER_URL_DETAIL='/home/materialmaster/details';

    static readonly LAYOUT = {
        RELATIONSHIP_TAB : 'REL1',
        CONFIGURATION_ITEMS : 'CI_1',
        SERVICE_PARTNER : 'SP_1',
        ERROR_LOG : 'EL_1'
    }
}
