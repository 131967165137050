import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JwtService } from './JwtService';
import { Observable } from 'rxjs/internal/Observable';
import { interval } from 'rxjs';
import { Api } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private refreshInterval$: Observable<number>;
  private refreshInterval = 10 * 60 * 1000;

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
  ) {
    this.refreshInterval$ = interval(this.refreshInterval)
      .pipe(
        tap(() => this.refreshToken())
      );
  }

  setRefreshInterval$(value: Observable<number>): void{
    this.refreshInterval$ = value;
  }
  
  getRefreshInterval$(): Observable<number>{
    return this.refreshInterval$;
  }

  refreshToken(): void {
    this.http.post(Api.Token.RefreshToken, {
      "access_token": this.jwtService.getToken()
    }).subscribe((response: any) => {
      this.jwtService.setToken(response.access_token);
    })
  }

  startRefreshInterval(): void {
    this.refreshInterval$.subscribe();
  }
}
