import { Component } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor(public readonly errorService: ErrorService) { }

  public onErrorModalClose()
  {
    this.errorService.hasError = false;
    this.errorService.errorDetails = new HttpErrorResponse({});
  }
  
}