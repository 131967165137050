import { Component, Inject } from '@angular/core';
import { UpdateService } from 'src/app/shared/services/update-service.service';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent {
  constructor(@Inject(UpdateService) public readonly updateService: UpdateService){
  }
}
