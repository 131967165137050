import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';

import { SqlServiceService } from 'src/app/core/sql-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { RoleService } from 'src/app/services/role.service';
import { FunctionalGroup, FunctionalGroupDataCard, FunctionalGroupData, UserData, SlpDetailsCard } from 'src/app/models';

@Component({
  selector: 'app-referene-systems-form',
  templateUrl: './referene-systems-form.component.html',
  styleUrls: ['./referene-systems-form.component.scss']
})
export class RefereneSystemsFormComponent {
  @Input() slpDetailsCardData: SlpDetailsCard;
  @Input() selectedAllFGDataArray: FunctionalGroupDataCard[];
  @Input() selectedUniqueFGDataArray: FunctionalGroupDataCard[];
  @Input() savedFgInfoMap: Map<number, FunctionalGroupData[]> = new Map<number, FunctionalGroupData[]>();
  @Input() savedSumIBDataCache: Map<string, number>;
  @Input() savedSelectedFGPartsCardsId: number;
  @Input() savedShowFGDataId: string;
  @Output() updateFgInfoMap: EventEmitter<{ cardId: number, savedFgInfoArray: FunctionalGroupData[] }> = new EventEmitter<{ cardId: number, savedFgInfoArray: FunctionalGroupData[] }>();

  isReadOnly: boolean = false;
  fgForm: FormGroup;
  showerror = false;
  slpHourRate: number = 0
  slpName: string = ""
  showFGDataId: string = ""
  showFGDataCardId: number
  isFunctionalGroupInputData: boolean = false
  isReferenceSystemDataSubmitted: boolean = false
  selectedFGPartsCardsId: number = -1
  FGTableSpinner: boolean = false
  activeFG: string = "";
  showCreateSlpSuccessDialog: boolean = false;
  visibilebutton: boolean = false;

  totalcostPerPartRef: number = 0;
  totalcostPerPartTarget: number = 0;
  takerateRef: number = 100;
  takerateTar: number = 100;
  displayAll = false;

  fgInfoArray: FunctionalGroupData[] = [];
  fgInfoArrayAll: FunctionalGroupData[] = [];
  SecondFGArray: FunctionalGroup[] = [];

  dropdownVisible: boolean = false;
  showSuccessDialog: boolean = false;
  lasttakerateref: number = 100;
  previousTakeRateTargetValue: number = 100;
  recentlySavedSlpId: number;

  constructor(
    private sqlServiceService: SqlServiceService,
    public authService: AuthService,
    private roleService: RoleService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.setPermissions();
    this.initializeForm();
    this.setSlpDetails();
    this.loadSavedFGPartsData();
  }

  setPermissions() {
    this.isReadOnly = !this.roleService.isDeveloperOrProjectManager();
  }

  initializeForm(): void {
    this.fgForm = this.formBuilder.group({
      FGInfoArray: this.formBuilder.array([]),
    });
  }

  setSlpDetails(): void {
    this.slpHourRate = this.slpDetailsCardData.hourRate;
    this.slpName = this.slpDetailsCardData.projectName;
  }

  loadSavedFGPartsData(): void {
    if (this.savedSelectedFGPartsCardsId !== -1) {
      this.showFGDataId = this.savedShowFGDataId;
      this.selectedFGPartsCardsId = this.savedSelectedFGPartsCardsId;
      this.showFGData({ id: this.showFGDataId, cardId: this.selectedFGPartsCardsId });
    }
  }

  toggleFormState(showError: boolean, showFunctionalGroup: boolean, isSubmitted: boolean): void {
    this.showerror = showError;
    this.isFunctionalGroupInputData = showFunctionalGroup;
    this.isReferenceSystemDataSubmitted = isSubmitted;
  }

  cancelToggle() {
    this.toggleFormState(false, true, false);
  }

  confirmToggle() {
    this.toggleFormState(false, false, false);
    this.showFGData({ id: this.showFGDataId, cardId: this.showFGDataCardId })
  }

  toggleFGPartsCardsClick(cardId: number): void {
    if (this.isFunctionalGroupInputData && !this.isReferenceSystemDataSubmitted) {
      this.showerror = true;
    }
    else {
      if (this.selectedUniqueFGDataArray[this.selectedFGPartsCardsId]) {
        this.selectedUniqueFGDataArray[this.selectedFGPartsCardsId]["isSelected"] = false;
      }
      this.selectedFGPartsCardsId = cardId;
      if (this.selectedUniqueFGDataArray[cardId]) {
        this.selectedUniqueFGDataArray[cardId]["isSelected"] = true;
        this.selectedUniqueFGDataArray[cardId]["REFCOST"] = parseFloat((this.fgInfoArray[0]?.TOTALCOSTPERPARTREFORFG)?.toFixed(2));
        this.selectedUniqueFGDataArray[cardId]["TARGETCOST"] = parseFloat((this.fgInfoArray[0]?.TOTALCOSTPERPARTTARGETORFG)?.toFixed(2));
      }
    }
  }

  createFGGroup(data: any): FormGroup {
    return this.formBuilder.group({
      FG: [data.FG],
      IVK: [data.IVK],
      MATERIAL: [data.MATERIAL],
      TAKERATEREFERENCE: [{ value: data.TAKERATEREFERENCE, disabled: this.isReadOnly }, Validators.required],
      TAKERATETARGET: [{ value: data.TAKERATETARGET, disabled: this.isReadOnly }, Validators.required],
      WORK: [data.WORK],
      WORKTARGET: [{ value: data.WORKTARGET, disabled: this.isReadOnly }, Validators.required],
      TRAVEL: [data.TRAVEL],
      TRAVELTARGET: [{ value: data.TRAVELTARGET, disabled: this.isReadOnly }, Validators.required],
      PRICE: [data.PRICE],
      PRICETARGET: [{ value: data.PRICETARGET, disabled: this.isReadOnly }, Validators.required],
      MTBF: [data.MTBF],
      MTBFTARGET: [{ value: data.MTBFTARGET, disabled: this.isReadOnly }, Validators.required],
      COSTPERPARTREFERENCE: [data.COSTPERPARTREFERENCE, Validators.required],
      COSTPERPARTTARGET: [{ value: data.COSTPERPARTTARGET, disabled: true }, Validators.required],
      INFLUENCE: [data.INFLUENCE],
      INFLUENCETARGET: [{ value: data.INFLUENCETARGET, disabled: true }, Validators.required],
      SYSTEMATID: [data.SYSTEMATID],
      SLPNAME: [data.SLPNAME],
      isNew: [data.isNew],
      ADDEDPART: [data.ADDEDPART],
    });
  }
  

  populateTableData(data: any[]) {
    const fgInfoArray = this.fgForm.get('FGInfoArray') as FormArray;
    fgInfoArray.clear();
    data.forEach(item => {
      fgInfoArray.push(this.createFGGroup(item));
    });
  }

  async setSumIbData(matid: string) { 
    let sumIB = 0
    if(this.savedSumIBDataCache.has(matid)) {
      sumIB = this.savedSumIBDataCache.get(matid) ?? 0;
    } else {
      sumIB = await lastValueFrom(this.sqlServiceService.getSumIB(matid));
      this.savedSumIBDataCache.set(matid, sumIB);
    }
    return sumIB;
  }

  syncFgInfoArrayWithSecondFGArray(): void {
    const ivkSet = new Set(this.SecondFGArray.map(obj => obj.IVK));
    if (this.fgInfoArray.length > this.SecondFGArray.length) {
      this.fgInfoArray = this.fgInfoArray.filter(obj => ivkSet.has(obj.IVK) || obj.ADDEDPART == 1);
    }
  }

  async processSecondFGArray(): Promise<void> {
    for (const item of this.SecondFGArray) {
      try {
        const sumIB = await this.setSumIbData(item.SYSMATID);
        await this.updateFgInfoArray(item, sumIB);
      } catch (error) {
        console.error(`Error processing item with SYSMATID ${item.SYSMATID}:`, error);
      }
    }
  }

  async updateFgInfoArray(item: any, sumIB: number): Promise<void> {
    const existingFG = this.fgInfoArray.find(fg => fg.FG === item.FG && fg.IVK === item.IVK);
  
    if (!existingFG) {
      const newItem = this.createNewFgItem(item, sumIB);
      this.totalcostPerPartRef += newItem.COSTPERPARTREFERENCE;
      this.totalcostPerPartTarget += newItem.COSTPERPARTTARGET;
      this.fgInfoArray.push(newItem);
    } else {
      this.totalcostPerPartRef += existingFG.COSTPERPARTREFERENCE;
      this.totalcostPerPartTarget += existingFG.COSTPERPARTTARGET;
    }
  }
  
  createNewFgItem(item: any, sumIB: number): FunctionalGroupData {
    return {
      FG: item.FG,
      IVK: item.IVK,
      MATERIAL: item.MATERIAL,
      TAKERATEREFERENCE: 100,
      TAKERATETARGET: 100,
      WORK: parseFloat((item.WORK / this.slpHourRate).toFixed(2)),
      WORKTARGET: null,
      TRAVEL: parseFloat((item.TRAVEL / this.slpHourRate).toFixed(2)),
      TRAVELTARGET: null,
      PRICE: parseFloat(item.PRICE.toFixed(2)),
      PRICETARGET: null,
      MTBF: parseFloat(((sumIB / item.SPC) * (this.takerateTar / this.takerateRef)).toFixed(2)),
      MTBFTARGET: null,
      COSTPERPARTREFERENCE: parseFloat((item.TOTALCOST / (sumIB / item.SPC)).toFixed(2)),
      COSTPERPARTTARGET: parseFloat(((item.TOTALCOST / (sumIB / item.SPC)) * (this.takerateTar / this.takerateRef)).toFixed(2)),
      INFLUENCE: 0,
      INFLUENCETARGET: 0,
      SLPNAME: this.slpName,
      TOTALCOSTPERPARTREFORFG: 0,
      TOTALCOSTPERPARTTARGETORFG: 0,
      ADDEDPART:0,
    };
  }

  async showFGData(selectedFGCardData: { id: string, cardId: number }) {
    this.displayAll = false;
    const { id, cardId } = selectedFGCardData
    this.showFGDataId = id
    this.showFGDataCardId = cardId

    if (this.isFunctionalGroupInputData && !this.isReferenceSystemDataSubmitted) {
      this.showerror = true;
      return;
    }

    this.FGTableSpinner = true
    this.activeFG = id;
    this.visibilebutton = this.activeFG !== "No Part" && this.activeFG !== "No Part or Travel";

    this.totalcostPerPartRef = 0;
    this.totalcostPerPartTarget = 0;
    this.fgInfoArray = this.savedFgInfoMap.get(cardId) ?? [];

    this.SecondFGArray = this.selectedAllFGDataArray.filter(obj => obj.FG === id);
    this.syncFgInfoArrayWithSecondFGArray();

    await this.processSecondFGArray();

    this.fgInfoArray.forEach((item) => {
      item.INFLUENCE = parseFloat(((item.COSTPERPARTREFERENCE / this.totalcostPerPartRef) * 100).toFixed(2));
      item.INFLUENCETARGET = parseFloat(((item.COSTPERPARTTARGET ?? 0 / this.totalcostPerPartTarget) * 100).toFixed(2));
      item.TOTALCOSTPERPARTREFORFG = this.totalcostPerPartRef;
      item.TOTALCOSTPERPARTTARGETORFG = this.totalcostPerPartTarget;
    });
    this.toggleFGPartsCardsClick(cardId);
    this.populateTableData(this.fgInfoArray);
    this.FGTableSpinner = false
    this.isReferenceSystemDataSubmitted = false
  }

  allFGCalculate() {
    this.totalcostPerPartRef = 0;
    this.totalcostPerPartTarget = 0;
    this.fgInfoArrayAll = [];
    this.selectedAllFGDataArray.forEach((item) => {
      const newItem: FunctionalGroupData = {
        FG: item.FG,
        IVK: item.IVK,
        MATERIAL: item.MATERIAL,
        TAKERATEREFERENCE: 100,
        TAKERATETARGET: 100,
        WORK: parseFloat((item.WORK / this.slpHourRate).toFixed(2)),
        WORKTARGET: 0,
        TRAVEL: parseFloat((item.TRAVEL / this.slpHourRate).toFixed(2)),
        TRAVELTARGET: 0,
        PRICE: item.PRICE,
        PRICETARGET: 0,
        MTBF: parseFloat((item.MTBF * this.takerateRef).toFixed(2)),
        MTBFTARGET: 0,
        COSTPERPARTREFERENCE: parseFloat((item.TOTALCOST / item.MTBF).toFixed(2)),
        COSTPERPARTTARGET: parseFloat(((item.TOTALCOST / item.MTBF) * (this.takerateTar / this.takerateRef)).toFixed(2)),
        INFLUENCE: 0,
        INFLUENCETARGET: 0,
        SLPNAME: this.slpName,
        TOTALCOSTPERPARTREFORFG: 0,
        TOTALCOSTPERPARTTARGETORFG: 0,
        ADDEDPART :0
      }
      this.totalcostPerPartRef += newItem.COSTPERPARTREFERENCE;
      this.totalcostPerPartTarget += newItem.COSTPERPARTTARGET;
      this.fgInfoArrayAll.push(newItem);
    });

    this.fgInfoArrayAll.forEach((item) => {
      item.INFLUENCE = parseFloat(((item.COSTPERPARTREFERENCE / this.totalcostPerPartRef) * 100).toFixed(2));
      item.INFLUENCETARGET = parseFloat(((item.COSTPERPARTTARGET ?? 0/ this.totalcostPerPartTarget) * 100).toFixed(2));
      item.TOTALCOSTPERPARTREFORFG = this.totalcostPerPartRef;
      item.TOTALCOSTPERPARTTARGETORFG = this.totalcostPerPartTarget;
    });
  }

  receiveDataFromChild(data: string) {
    if (data == "showAll") {
      this.allFGCalculate();
      this.displayAll = true;
    }
  }

  getFormattedCurrentDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  mapFormValuesToUserData(fgInfoArray: FunctionalGroupData[]): UserData[] {
    return fgInfoArray.map(item => ({
      SLPID: this.recentlySavedSlpId,
      MATNAME: item.MATERIAL,
      FUNCTIONALGROUP: item.FG,
      IVK: item.IVK,
      SLPNAME: this.slpName,
      TAKERATEREFERENCE: item.TAKERATEREFERENCE,
      TAKERATETARGET: item.TAKERATETARGET,
      WORKREFERENCE: item.WORK,
      WORKTARGET: item.WORKTARGET ?? 0,
      TRAVELREFERENCE: item.TRAVEL,
      TRAVELTARGET: item.TRAVELTARGET ?? 0,
      PRICEREFERENCE: item.PRICE,
      PRICETARGET: item.PRICETARGET ?? 0,
      MTBFREFERENCE: item.MTBF,
      MTBFTARGET: item.MTBFTARGET ?? 0,
      COSTPERPARTREFERENCE: item.COSTPERPARTREFERENCE,
      COSTPERPARTTARGET: item.COSTPERPARTTARGET,
      INFLUENCEREFERENCE: item.INFLUENCE,
      INFLUENCETARGET: item.INFLUENCETARGET,
      CREATEDBY: this.authService.getAccount()?.username.toString(),
      CREATEDDATE: this.getFormattedCurrentDate(),
      ADDEDPART : item.ADDEDPART,
    }));
  }

  async ReferencePageOnSubmit() {
    this.recentlySavedSlpId = await lastValueFrom(this.sqlServiceService.getRecentSavedSlpProjectId()) ?? -1;
      // Extract form values and map them to newArray
      this.fgInfoArray = this.fgForm.value.FGInfoArray;
      this.updateFgInfoMap.emit({ cardId: this.selectedFGPartsCardsId, savedFgInfoArray: this.fgInfoArray });
      const userDataMappedArray = this.mapFormValuesToUserData(this.fgForm.value.FGInfoArray);
      this.sqlServiceService.addRecord3(userDataMappedArray).subscribe(() => {
      this.isFunctionalGroupInputData = false;
      this.isReferenceSystemDataSubmitted = false;
      this.showCreateSlpSuccessDialog = true;
      });
  }

  validateInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    // Regex pattern to allow numbers with up to 5 decimal places
    const pattern = /^\d+(\.\d{1,5})?$/;

    // Check if the input value matches the pattern
    if (!pattern.test(value)) {
      // If the value doesn't match, show an alert or error message
      inputElement.setCustomValidity('Please enter a valid number with up to 5 decimal places.');
    } else {
      // If the value is valid, clear the custom validity message
      inputElement.setCustomValidity('');
    }

    // Report validity for real-time validation feedback
    inputElement.reportValidity();
  }

  closeCreateSlpDialog() {
    this.showCreateSlpSuccessDialog = false;
  }

  calculateCostPerPartTarget(
    mtbfValue: number,
    travelTargetValue: number,
    workTargetValue: number,
    priceTargetValue: number,
    takeRateTargetValue: number,
    takeRateRefValue: number,
    hourRate: number
  ): number {
    if (mtbfValue <= 0 && takeRateTargetValue <= 0 && takeRateRefValue <= 0) {
      return 0;
    }

    const totalWorkCost = ((travelTargetValue + workTargetValue) * hourRate + priceTargetValue) / mtbfValue;
    const takeRateRatio = takeRateTargetValue / takeRateRefValue;

    const costPerPartTarget = totalWorkCost * takeRateRatio * 100;

    return parseFloat(costPerPartTarget.toFixed(2));

  }

  getControlValue(control: any): number {
    const parsedValue = parseFloat(control?.value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  }

  onFGInputChange(index: number): void {
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;

    const travelTargetValue = this.getControlValue(fgGroup.get('TRAVELTARGET'));
    const workTargetValue = this.getControlValue(fgGroup.get('WORKTARGET'));
    const priceTargetValue = this.getControlValue(fgGroup.get('PRICETARGET'));
    const mtbfRefValue = this.getControlValue(fgGroup.get('MTBF'));
    const mtbfTarValue = this.getControlValue(fgGroup.get('MTBFTARGET'));
    const takeRateTargetValue = this.getControlValue(fgGroup.get('TAKERATETARGET'));
    const takeRateRefValue = this.getControlValue(fgGroup.get('TAKERATEREFERENCE'));
    const hourRate = this.slpHourRate;
    const mtbfValue = fgGroup.get('MTBFTARGET')?.dirty ? mtbfTarValue : mtbfRefValue
    const newCostPerPartTarget = this.calculateCostPerPartTarget(
      mtbfValue,
      travelTargetValue,
      workTargetValue,
      priceTargetValue,
      takeRateTargetValue,
      takeRateRefValue,
      hourRate
    );
    if(!Number.isNaN(newCostPerPartTarget) && newCostPerPartTarget != null) {
      fgGroup.patchValue({ COSTPERPARTTARGET: newCostPerPartTarget });
    } else {
      fgGroup.patchValue({ COSTPERPARTTARGET: 0 });
    }
    
    this.updateInfluenceTargets();
  }
    
  updateInfluenceTargets(): void {
    const totalCostPerPartTarget = this.calculateTotalCostPerPartTarget();

    this.FGInfoArray.controls.forEach(fgGroup => {
      const costPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'))
      const influenceTargetValue = this.calculateInfluenceTarget(costPerPartTargetValue, totalCostPerPartTarget);
      if(!Number.isNaN(influenceTargetValue) && influenceTargetValue != null) {
        fgGroup.patchValue({ INFLUENCETARGET: influenceTargetValue });
      } else {
        fgGroup.patchValue({ INFLUENCETARGET: 0 });
      }
    });
  }

  calculateTotalCostPerPartTarget(): number {
    return this.FGInfoArray.controls
      .map(fg => {
        const value = parseFloat(fg.get('COSTPERPARTTARGET')?.value);
        return isNaN(value) ? 0 : value;
      })
      .reduce((acc, val) => acc + val, 0);
  }

  calculateInfluenceTarget(costPerPartTargetValue: number, totalCostPerPartTarget: number): number {
    return totalCostPerPartTarget > 0
      ? parseFloat(((costPerPartTargetValue / totalCostPerPartTarget) * 100).toFixed(2))
      : 0;
  }

  getMatchingFunctionalGroup(fgGroup: FormGroup): FunctionalGroup {
    return this.SecondFGArray.find(item =>
      item.FG === fgGroup.get('FG')?.value && item.IVK === fgGroup.get('IVK')?.value
    ) ?? this.SecondFGArray[0];
  }

  onTakeRateRefChange(index: number): void {
    //this function is used to alter the value present in the columns MTBF and costperparttarget 
    //when the takerateref user input value is changed according to the formula.
    this.isFunctionalGroupInputData = true;
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;
    const ourItem: FunctionalGroup = this.getMatchingFunctionalGroup(fgGroup);

    const takeRateRefValue = this.getControlValue(fgGroup.get('TAKERATEREFERENCE'));
    const mtbfRefValue = this.getControlValue(fgGroup.get('MTBF'));
    const costPerPartTargetValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));

    if (takeRateRefValue > 0 && this.lasttakerateref > 0 && mtbfRefValue > 0) {
      // Update MTBF based on the new TAKERATEREFERENCE value
      const newMTBF = parseFloat(((mtbfRefValue * takeRateRefValue) / this.lasttakerateref).toFixed(2));
      fgGroup.patchValue({ MTBF: newMTBF });
    } else if (takeRateRefValue > 0) {
      // Reset MTBF to the original value
      fgGroup.patchValue({ MTBF: (ourItem.MTBF) * takeRateRefValue });
    } else {
      // Reset MTBF to zero if TAKERATEREFERENCE is zero or invalid
      fgGroup.patchValue({ MTBF: 0 });
    }

    if (takeRateRefValue > 0 && this.lasttakerateref > 0 && costPerPartTargetValue > 0) {
      // Update COSTPERPARTTARGET based on the new TAKERATEREFERENCE value
      const newCostPerPartTarget = parseFloat(((costPerPartTargetValue * this.lasttakerateref) / takeRateRefValue).toFixed(2));
      fgGroup.patchValue({ COSTPERPARTTARGET: newCostPerPartTarget });
    } else if (takeRateRefValue > 0) {
      // Reset COSTPERPARTTARGET to the original value
      fgGroup.patchValue({ COSTPERPARTTARGET: (ourItem.TOTALCOST / ourItem.MTBF) * 100 / takeRateRefValue });
    } else {
      // Reset COSTPERPARTTARGET to zero if TAKERATEREFERENCE is zero or invalid
      fgGroup.patchValue({ COSTPERPARTTARGET: 0 });
    }

    this.updateInfluenceTargets();
    this.lasttakerateref = takeRateRefValue ?? 0;
  }

  onTakeRateTargetChange(index: number): void {
    //this function is used to alter the value present in the column costperparttarget 
    //when the takeratetarget user input value is changed according to the formula.
    this.isFunctionalGroupInputData = true;
    const fgGroup = this.FGInfoArray.at(index) as FormGroup;
    const ourItem: FunctionalGroup = this.getMatchingFunctionalGroup(fgGroup);


    const takeRateTargetValue = this.getControlValue(fgGroup.get('TAKERATETARGET'));
    const costPerPartTargetControlValue = this.getControlValue(fgGroup.get('COSTPERPARTTARGET'));

    if (takeRateTargetValue > 0 && this.previousTakeRateTargetValue > 0 && costPerPartTargetControlValue > 0) {
      // Calculate and update COSTPERPARTTARGET based on the new TAKERATETARGET value
      const newCostPerPartTarget = parseFloat(((costPerPartTargetControlValue * takeRateTargetValue) / this.previousTakeRateTargetValue).toFixed(2));
      fgGroup.patchValue({ COSTPERPARTTARGET: newCostPerPartTarget });
    } else if (takeRateTargetValue > 0) {
      // If TAKERATETARGET is positive, reset COSTPERPARTTARGET to the original value
      fgGroup.patchValue({ COSTPERPARTTARGET: (ourItem.TOTALCOST / ourItem.MTBF) * takeRateTargetValue / 100 });
    } else {
      // If TAKERATETARGET is zero or invalid, reset COSTPERPARTTARGET to zero
      fgGroup.patchValue({ COSTPERPARTTARGET: 0 });
    }
    this.updateInfluenceTargets();
    this.previousTakeRateTargetValue = takeRateTargetValue ?? 0;
  }

  FGInputDataUpdated(): void {
    this.isFunctionalGroupInputData = true
  }

  get FGInfoArray() {
    return this.fgForm.get('FGInfoArray') as FormArray;
  }

  onDropdownChange() {
    const newRowData = {
      FG: this.activeFG,
      IVK: "",
      MATERIAL: "",
      TAKERATEREFERENCE: 100,
      TAKERATETARGET: 0,
      WORK: 0,
      WORKTARGET: null,
      TRAVEL: 0,
      TRAVELTARGET: null,
      PRICE: 0,
      PRICETARGET: null,
      MTBF: 0,
      MTBFTARGET: null,
      COSTPERPARTREFERENCE: 0,
      COSTPERPARTTARGET: 0,
      INFLUENCE: 0,
      INFLUENCETARGET: 0,
      isNew: true,
      ADDEDPART:1,
    };
    this.FGInfoArray.push(this.createFGGroup(newRowData));
    this.dropdownVisible = false; // Hide the dropdown after adding the new row
  }

  onPreviousButtonClick() {
    if (this.isFunctionalGroupInputData && !this.isReferenceSystemDataSubmitted) {
      this.showerror = true;
    }
  }

}
