<h2>Cookie policy: This application does not utilize any cookies.</h2>
<h2>Siemens Healthineers Intranet Data Privacy Notice</h2>
<div>
    <p><strong>Last update: August, 2020</strong>
    </p>
    <p>This Privacy Notice applies to the intranet sites, internal applications and tools (each a&nbsp;
        "<strong>Siemens Healthineers Intranet Offering</strong>") operated by Siemens Healthcare GmbH,
        Karlheinz-Kaske-Str. 5, 91052 Erlangen, Germany ("<strong>Siemens Healthineers</strong>").</p>
    <p>Protecting the security and privacy of your personal data is important to Siemens Healthineers.
        Therefore, Siemens Healthineers processes personal data in compliance with applicable laws on
        data protection and data security.</p>
</div>
<div>
    <p><strong>1. Categories of personal data processed</strong></p>
    <p>When visiting and using Siemens Healthineers Intranet Offerings, Siemens Healthineers may process
        the following personal data about you:</p>
    <ul>
        <li>
            <p>Personal data that you actively and voluntarily provide via the Siemens Healthineers
                Intranet Offerings (e.g., when registering, contacting us with your inquiries,
                participating in surveys or Siemens Healthineers events and competitions etc.),
                including name, business e-mail address and telephone number, information submitted as
                part of request, etc.; and</p>
        </li>
        <li>
            <p>Information that is automatically sent to us by your web browser or device, such as your
                IP-address, device type, browser type, referring site, sites accessed during your visit,
                the date and time of each visitor request, the type and size of the files retrieved as
                well as further status information.</p>
        </li>
    </ul>
</div>
<div>
    <p><strong>2. Purpose of the Processing</strong></p>
    <p>We process your personal data for the following purposes:</p>
    <ul>
        <li>
            <p>To provide the Siemens Healthineers Intranet Offering's services and functions and to
                administer your use of the Siemens Healthineers Intranet Offering;</p>
        </li>
        <li>
            <p>To verify your identity;</p>
        </li>
        <li>
            <p>To answer and fulfill your specific requests; and</p>
        </li>
        <li>
            <p>As reasonably necessary to conduct security analysis and create related reports and
                prevent illegal activities, including attacks on Siemens Healthineers' information
                technology systems.</p>
        </li>
    </ul>
</div>
<div>
    <p><strong>3. Cookies</strong></p>
    <p>When you visit and use Siemens Healthineers Intranet Offerings, information may be stored on your
        terminal device in the form of "cookies". Cookies are small files that are stored on your
        terminal device and save certain settings and data to exchange with the Siemens Healthineers
        Intranet Offerings via your browser. For example, cookies enable us to store your language
        selection or to enhance your experience by improving the user friendliness of the Siemens
        Healthineers Intranet Offering (for example with Adobe Analytics, see below). If you do not want
        us to recognize your terminal device, please configure your browser to erase all cookies from
        your device, to block all cookies or to receive a warning before a cookie is stored. Please note
        that certain functions of the Siemens Healthineers Intranet Offering no longer work, or not
        correctly, without cookies.</p>
    <p>We use Adobe Analytics for the purpose of constantly improving the Siemens Healthineers Intranet
        Offerings or detecting errors. For this purpose, Adobe Analytics anonymously stores and analyzes
        various kinds of data, including the visited site and the site’s metadata, the time of the
        visit, clicked links (link title, URL) and the operating system and browser used.</p>
    <p><a href="http://www.d1.sc.omtrdc.net/optout.html?locale=en_US&amp;second=1&amp;second_has_cookie=0"
            title="Please click here">Please click here</a>&nbsp;to prevent Adobe Analytics from
        aggregating and analyzing the data collected from your visit of Siemens Healthineers Intranet
        Offerings.</p>
    <p>By doing this, you will create a so-called exclusion cookie in your browser. It contains no
        personal data, but technically enables Adobe Analytics to permanently recognize your Cookie
        preference. If you delete this exclusion cookie or if you change your computer or browser, you
        will need to create the exclusion cookie again.</p>
</div>
<div>
    <p><strong>4. Legal Basis of the processing</strong></p>
    <p>The processing of your personal data is necessary to meet the aforementioned purposes. The legal
        basis for Siemens Healthineers processing data about you is that such processing is necessary
        for the purposes of:</p>
    <ul>
        <li>
            <p>Siemens Healthineers exercising its rights and performing its obligations in connection
                with any contract we make with you (Article 6 (1) (b) General Data Protection
                Regulation);</p>
        </li>
        <li>
            <p>Compliance with Siemens Healthineers’ legal obligations (Article 6 (1) (c) General Data
                Protection Regulation); and/or</p>
        </li>
        <li>
            <p>Legitimate interests pursued by Siemens Healthineers (Article 6 (1) (f) General Data
                Protection Regulation).&nbsp; Generally, the legitimate interest pursued by Siemens
                Healthineers in relation to our use of your personal data is the efficient performance
                or management of your use of the Siemens Healthineers Intranet Offerings, but we may
                disclose other legitimate interests for specific uses.</p>
        </li>
    </ul>
    <p>In some cases, we may ask if you consent to the relevant use of your personal data. In such
        cases, the legal basis for Siemens Healthineers processing that data about you may (in addition
        or instead) be that you have consented (Article 6 (1) (a) General Data Protection Regulation).
    </p>
</div>
<div>
    <p><strong>5. Links to other Websites</strong></p>
    <p>This Privacy Notice applies only to Siemens Healthineers Intranet Offerings and not to other
        websites or applications operated by third parties. We may provide links to other websites and
        applications which we believe may be of interest to you. Siemens Healthineers is not responsible
        for the privacy practices of such other websites or applications.</p>
</div>
<div>
    <p><strong>6. Transfer and disclosure of personal data</strong></p>
    <p>Siemens Healthineers may transfer your personal data to:</p>
    <ul>
        <li>
            <p>Other Siemens Healthineers and Siemens group companies or third parties - e.g. sales
                partners or suppliers - companies in connection with the offering of the Siemens
                Healthineers Intranet Offering;</p>
        </li>
        <li>
            <p>Third parties which provide IT services to Siemens Healthineers and which process such
                data only for the purpose of such services (e.g., hosting or IT maintenance and support
                services); and/or</p>
        </li>
        <li>
            <p>Third parties in connection with complying with legal obligations or establishing,
                exercising or defending rights or claims (e.g., for court and arbitration proceedings,
                to law enforcement authorities and regulators, to attorneys and consultants).</p>
        </li>
    </ul>
    <p>Sometimes the recipients to whom Siemens Healthineers transfers your personal data are located in
        countries in which applicable laws do not offer the same level of data protection as the laws of
        your home country.</p>
    <p>In such cases, if required by applicable law, Siemens Healthineers takes measures to implement
        appropriate and suitable safeguards for the protection of your personal data.&nbsp; In
        particular:</p>
    <p>We ensure that personal data is only transferred to recipients in third countries if the
        respective recipient</p>
    <ol>
        <li>
            <p>has entered into EU Standard Contractual Clauses; or</p>
        </li>
        <li>
            <p>has implemented Binding Corporate Rules in its organization; or</p>
        </li>
        <li>
            <p>applies another appropriate and suitable safeguard as required by applicable law.</p>
        </li>
    </ol>
    <p>Please follow the links below for further information about the respective safeguards:</p>
    <ul>
        <li>
            <p>EU Standard Contractual Clauses:&nbsp;<a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
                    title="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en">https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en</a>
            </p>
        </li>
        <li>
            <p>Binding Corporate Rules:&nbsp;<a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en"
                    title="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en">https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en</a>
            </p>
        </li>
    </ul>
    <p>You may request further information about the safeguards implemented in relation to specific
        transfers by contacting the Siemens Healthineers Data Privacy Organization (see contact
        information under "Contact").</p>
    <p>Personal data published by you on Siemens Healthineers Intranet Offerings (such as chat rooms or
        forums) may be globally accessible to other users of the respective Siemens Healthineers
        Intranet Offering.</p>
</div>
<div>
    <p><strong>7. Retention Periods</strong></p>
    <p>Unless indicated otherwise at the time of the collection of your personal data (e.g. within a
        form completed by you), we erase your personal data if the retention of that personal data is no
        longer necessary (i) for the purposes for which they were collected or otherwise processed, or
        (ii) to comply with legal obligations (such as retention obligations under tax or commercial
        laws).</p>
</div>
<div>
    <p><strong>8. Withdrawal of consent</strong></p>
    <p>In case you declared your consent for the processing of certain personal data by Siemens
        Healthineers, you have the right to withdraw the consent at any time with future effect, i.e.
        the withdrawal of the consent does not affect the lawfulness of processing based on the consent
        before its withdrawal. If the consent is withdrawn, Siemens Healthineers may only further
        process the personal data where there is another legal ground for the processing.</p>
</div>
<div>
    <p><strong>9. Your Rights</strong></p>
    <p>Subject to the statutory requirements, you are entitled to:</p>
    <ul>
        <li>
            <p>Obtain from Siemens Healthineers confirmation as to whether or not personal data
                concerning you are being processed, and where that is the case, access to the personal
                data;</p>
        </li>
        <li>
            <p>Obtain&nbsp;from Siemens Healthineers the rectification of inaccurate personal data
                concerning you;</p>
        </li>
        <li>
            <p>Obtain from Siemens Healthineers the erasure of your personal data;</p>
        </li>
        <li>
            <p>Obtain from Siemens Healthineers restriction of processing regarding your personal data;
            </p>
        </li>
        <li>
            <p>Data portability concerning personal data, which you actively provided; and</p>
        </li>
        <li>
            <p>Object, on grounds relating to your particular situation, to processing of personal data
                concerning you.</p>
        </li>
    </ul>
</div>
<div>
    <p><strong>10. Contact for questions and complaints, data protection officer</strong></p>
    <p>The Data Protection Officer of Siemens Healthineers and the Siemens Healthineers Data Privacy
        Organization provide support in all questions relating to data privacy as well as questions,
        comments, concerns or complaints. The Data Protection Officer and the Siemens Healthineers Data
        Privacy Organization can be contacted at</p>
    <p><a href="mailto:dataprivacy.func@siemens-healthineers.com"
            title="mailto:dataprivacy.func@siemens-healthineers.com">mailto:dataprivacy.func@siemens-healthineers.com</a>
    </p>
    <p>The Siemens Healthineers Data Protection Officer and the Siemens Healthineers Data Privacy
        Organization will always use best efforts to address and remedy your request or complaint. In
        addition to contacting the Data Protection Officer of Siemens Healthineers and the Siemens
        Healthineers Data Privacy Organization, you also have the possibility at any time to contact the
        competent data protection supervisory authority with your enquiry or complaint.</p>
    <p>An overview of the national and international data protection authorities is available&nbsp;<a
            href="https://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">here</a>.</p>
</div>
<div>
    <p><strong>11. Siemens Healthineers Employee Privacy Notice</strong></p>
    <p>Further information regards Siemens Healthineers’ data privacy practices related to the
        processing of your personal data in the employment relationship can be found in the&nbsp;<a
            href="https://www.carl.siemens.com/api-carl/file?id=245fe341-488b-4456-af7f-a9310108351b"
            title="https://www.carl.siemens.com/api-carl/file?id=245fe341-488b-4456-af7f-a9310108351b"
            rel="noopener noreferrer"
            target="_blank">Siemens Healthineers
            Employee Privacy Notice</a>.
    </p>
</div>