import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AuthService } from './services';
import { DataTransformationService } from './data-transformation.service';
import { API_URLS } from './constants';
import { SlpProjectStatus } from 'src/app/models';
import { RoleService } from '../services/role.service';


@Injectable({
  providedIn: 'root'
})
export class SqlServiceService {

  constructor(private http: HttpClient, public authService: AuthService, private dataTransformationService: DataTransformationService,private roleService: RoleService) {}
 
  getFGData(matId : string): Observable<any> {
    const requestData = {
      MATID: matId
    };
    const params = new HttpParams({ fromObject: requestData });

    return this.http.get(API_URLS.FG_DATA, { params }).pipe(map((data: any) => 
    this.dataTransformationService.transformDataToUpper(data))
    );
  }

  getUserRoles(token : string): Observable<any> {
    const requestData = {
      TOKEN: token
    };
    const params = new HttpParams({ fromObject: requestData });
    return this.http.get(API_URLS.GET_USER_ROLES, { params }).pipe(map((data: any) => {
      return data;
    }));
  }

  getMaterialNameData(matId : string): Observable<any> {
    const requestData = {
      MATID: matId
    };
    const params = new HttpParams({ fromObject: requestData });
    return this.http.get(API_URLS.SERVICE_PARTS_MATERIAL_NAME, { params }).pipe(map((data: any) => {
      return data;
    }));
  }

  getRecentSavedSlpProjectId(): Observable<number> {
    return this.http.get<number>(API_URLS.GET_RECENT_SLP_ID);
  }
  
  checkIfIvkExistsInServicePartsTable(Ivk : string): Observable<any> {
    const requestData = {
      IVK: Ivk
    };
    const params = new HttpParams({ fromObject: requestData });
    return this.http.get(API_URLS.CHECK_IF_IVK_EXISTS_IN_SERVICE_PARTS, { params }).pipe(map((data: any) => {
      return data;
    }));
  }

  getNewSystems(): Observable<any> {
    return this.http.get(API_URLS.SYSTEM_FETCH_URL).pipe(map((data: any) => 
    this.dataTransformationService.transformDataToUpper(data))
    );
  }

  getUniqueFG(selectedBusinessLineValue : string): Observable<any> {
    const requestData = {
      BUSINESSLINE: selectedBusinessLineValue
    };
    const params = new HttpParams({ fromObject: requestData });
    return this.http.get(API_URLS.UNIQUE_FG_FETCH, { params }).pipe(map((data: any) => {
      return data; 
    }));
  }

  getBusinessLine(): Observable<any> {
    return this.http.get(API_URLS.BUSINESS_LINE_FETCH).pipe(map((data: any) => 
    this.dataTransformationService.transformDataToUpper(data))
    );
  }

  getNotificationCategories(): Observable<any>{
    return this.http.get(API_URLS.NOTIFICATION_CATEGORIES_FETECH).pipe(map((data:any) => 
      data
    ))
  }

  getMaterialServiceSystems(matId : string): Observable<any> {
    const requestData = {
      MATID: matId
    };
    const params = new HttpParams({ fromObject: requestData });
    return this.http.get(API_URLS.MATERIAL_FETCH_URL, { params }).pipe(map((data: any) => 
    this.dataTransformationService.transformDataToUpper(data))
    );
  }

  getFGparts(FGname : string): Observable<any> {
    const requestData = {
      FG: FGname
    };
    const params = new HttpParams({ fromObject: requestData });
    return this.http.get(API_URLS.FG_PARTS_FETCH, { params }).pipe(map((data: any) => 
      this.dataTransformationService.transformDataToUpper(data))
    );
  }

  getSubscribedSlps(): Observable<number[]>{
    const createdBy =  this.authService.getAccount()?.username ?? "";
    return this.http.get<number[]>(API_URLS.GET_SUBSCRIBED_SLPS, { params: { createdBy } });
  }

  toggleSubscribedSlps(slpId: number): Observable<string> { 
    const createdBy =  this.authService.getAccount()?.username ?? "";
    const body = { createdBy, slpId };
    return this.http.post<string>(API_URLS.TOGGLE_SUBSCRIBED_SLP, body);
  }
  
  getSubscribedSlpStatus(): Observable<SlpProjectStatus> {
    const userRoles = this.roleService.getRoles();
    const createdBy = this.authService.getAccount()?.username ?? "";

    return this.http.post<SlpProjectStatus>(
        API_URLS.GET_SUBSCRIBED_SLPS_STATUS,
        userRoles, // Send userRoles directly in the body as an array
        {
            params: { createdBy } // createdBy in query parameters
        }
    );
}


getSLPUserData(): Observable<any> {
  const userRoles = this.roleService.getRoles();
  return this.http.post(API_URLS.SLP_USER_DATA, userRoles).pipe(
    map((data: any) => this.dataTransformationService.transformDataToUpper(data))
  );
}

getServicePartData(systemID: string, slpID : number,hourRate : number,baseSystems :string,years :string,notifications:string): Observable<any> {
  const userRoles = this.roleService.getRoles();
  const requestData = {
    systemID: systemID,
    slpID : slpID,
    hourRate : hourRate,
    baseSystems : baseSystems,
    years : years,
    notifications : notifications
  };

  const params = new HttpParams({ fromObject: requestData });

  return this.http.post<any>(API_URLS.BASE_SYSTEM_SERVICE_PARTS_FETCH ,userRoles, { params }).pipe(map((data: any) => 
  this.dataTransformationService.transformComplexDataToUpper(data))
  );
}

callStoredProcForCreateSLP (slpID: number, hourRate: number, baseSystems: string, years: string, notifications: string): Promise<any> {
  const requestData = {
    slpID: slpID,
    hourRate: hourRate,
    baseSystems: baseSystems,
    years: years,
    notifications: notifications
  };

  // Return a Promise that resolves or rejects based on the HTTP response
  return new Promise((resolve, reject) => {
    this.http.post(API_URLS.CALL_STORED_PROCEDUREFORNEWSLP, requestData)
      .subscribe(
        (response) => {
          resolve(response); // Resolve the promise with the response
        },
        (error) => {
          console.warn(error);
          reject(error); // Reject the promise with the error
        }
      );
  });
}

callStoredProcForExistingSLP (slpID: number, hourRate: number, baseSystems: string, years: string, notifications: string): Promise<any> {
  const requestData = {
    slpID: slpID,
    hourRate: hourRate,
    baseSystems: baseSystems,
    years: years,
    notifications: notifications
  };

  // Return a Promise that resolves or rejects based on the HTTP response
  return new Promise((resolve, reject) => {
    this.http.post(API_URLS.CALL_STORED_PROCEDUREFOREXISTINGSLP, requestData)
      .subscribe(
        (response) => {
          console.warn(response);
          resolve(response); // Resolve the promise with the response
        },
        (error) => {
          console.warn(error);
          reject(error); // Reject the promise with the error
        }
      );
  });
}


  async updateServicePartsIbaseResultsOnHourlyRateChange(slpID: number, hourRate: number): Promise<any> {
    const requestData = {
      slpID: slpID,
      hourRate: hourRate,
    };
    try {
      const response = await lastValueFrom(this.http.post<any>(API_URLS.RECALCULATE_IBASE_BY_HOURLY_RATE, requestData));
      return response;
    } catch (error) {
      console.warn(error);
      return error;
    }
  }

getStoredProcedureFGdata(slpID : number,matID: string): Observable<any> {
  const userRoles = this.roleService.getRoles();
  const requestData = {
    slpID : slpID,
    matID: matID
  };

  const params = new HttpParams({ fromObject: requestData });

  return this.http.post<any>(API_URLS.GET_SAVED_FGDATA_FOR_RELOAD ,userRoles, { params }).pipe(map((data: any) => 
  this.dataTransformationService.transformComplexDataToUpper(data))
  );
}

getStoredProcedureOthersData(slpID : number,matID: string): Observable<any> {
  const userRoles = this.roleService.getRoles();
  const requestData = {
    slpID : slpID,
    matID: matID
  };

  const params = new HttpParams({ fromObject: requestData });

  return this.http.post<any>(API_URLS.GET_SAVED_OTHERS_FOR_RELOAD ,userRoles, { params }).pipe(map((data: any) => 
  this.dataTransformationService.transformComplexDataToUpper(data))
  );
}

getStoredProcedureNoPartData(slpID : number,matID: string): Observable<any> {
  const requestData = {
    slpID : slpID,
    matID: matID
  };

  const params = new HttpParams({ fromObject: requestData });

  return this.http.get<any>(API_URLS.GET_SAVED_NOPART_FOR_RELOAD , { params }).pipe(map((data: any) => 
  this.dataTransformationService.transformComplexDataToUpper(data))
  );
}

FetchReferenceSystemStoredDataForSLP(SELECTEDARRAY: any[], SLPID: number): Observable<any> {
  const payload = {
    SELECTEDARRAY: SELECTEDARRAY,
    SLPID: SLPID
  };
  
  return this.http.post<{ [key: string]: any[] }>(API_URLS.FETCH_REFERENCE_SYSTEMS_FOR_SLP, payload).pipe(
    map(data => this.dataTransformationService.transformKeysToUppercase(data))
  );
}

getCheckedDataForSLP(slpID : number): Observable<any> {
  const userRoles = this.roleService.getRoles();
  const requestData = {
    slpID : slpID
  };
  const params = new HttpParams({ fromObject: requestData });

  return this.http.post<any>(API_URLS.GET_CHECKED_DATA_FOR_SLP ,userRoles, { params }).pipe(map((data: any) => 
  this.dataTransformationService.transformComplexDataToUpper(data))
  );
}

getServicePartData2(systemID: string, FGname: string): Observable<any> {
  const requestData = {
    systemID: systemID,
    FGname: FGname,
  };

  const params = new HttpParams({ fromObject: requestData });

  return this.http.get<any>(API_URLS.SERVICE_PARTS_FETCH_2, { params }).pipe(map((data: any) => 
  this.dataTransformationService.transformDataToUpper(data))
  );
}

getServicePartOthers(systemID: string,slpID : number,hourRate : number,baseSystems :string,years :string,notifications:string): Observable<any> {
  const userRoles = this.roleService.getRoles();
  const requestData = {
    systemID: systemID,
    slpID : slpID,
    hourRate : hourRate,
    baseSystems : baseSystems,
    years : years,
    notifications : notifications
  };
  const params = new HttpParams({ fromObject: requestData });
  return this.http.post<any>(API_URLS.SERVICE_PARTS_FETCH_OTHERS,userRoles, { params }).pipe(map((data: any) =>
  this.dataTransformationService.transformComplexDataToUpper(data))
  );
}

getDistinctFG(slpName: string): Observable<any> {
  const requestData = {
    SLPNAME: slpName
  };
  const params = new HttpParams({ fromObject: requestData });
  return this.http.get<any>(API_URLS.DISTINCT_FG, { params }).pipe(map((data: any) =>
    this.dataTransformationService.transformDataToUpper(data))
    );
}

get3rdPageViewSLPData(slpId: string): Observable<any> {
  const requestData = {
    SLPID: slpId
  };
  const params = new HttpParams({ fromObject: requestData });
  return this.http.get<any>(API_URLS.SYSTEM_INFO_DATA_FOR_VIEW_SLP, { params }).pipe(map((data: any) =>
    this.dataTransformationService.transformDataToUpper(data))
    );
}

checkifIVKpresent(Ivk: string,businessLine : string): Observable<any> {
  const requestData = {
    IVK : Ivk,
    BUSINESSLINE:businessLine
  };
  const params = new HttpParams({ fromObject: requestData });
  return this.http.get<any>(API_URLS.CHECK_IF_IVK_EXISTS, { params }).pipe(map((data: any) => {
    return data;
  }));
}

getSumIB(matId: string): Observable<any> {
  const requestData = {
    matId : matId
  };
  const params = new HttpParams({ fromObject: requestData });
  return this.http.get<any>(API_URLS.SUM_IB, { params }).pipe(map((data: any) => {
    return data;
  }));
}

getServiceParts(): Observable<any> {
  return this.http.get(API_URLS.SERVICE_PARTS_URL).pipe(map((data: any) =>
  this.dataTransformationService.transformDataToUpper(data))
  );
}
  
addRecord(recordData: any, isSLPID: number): Promise<any> {
  const payload = {
    recordData: recordData,
    isSLPID: isSLPID
  };

  return this.http.post(API_URLS.USER_POST_URL, payload).toPromise();
}

changeStateInStoredProcedureTable(SELECTEDARRAY: any[], SLPID: number): Promise<any> {
  const payload = {
    SELECTEDARRAY: SELECTEDARRAY,
    SLPID: SLPID
  };
  return this.http.post(API_URLS.CHANGE_STATE_IN_STORED_PROCEDURE, payload).toPromise();
}


  updateFGName(oldFG: string, newFG: string): Observable<any> {
    const params = new HttpParams()
        .set('oldFG', oldFG)
        .set('newFG', newFG);

    return this.http.post(API_URLS.UPDATE_FG_NAME, null, { params: params });
}


  
  addFGrecord(recordData: any): Observable<any> {
    return this.http.post(API_URLS.FG_TABLE_POST, recordData);
  }

  addRecord3(recordData: any) : Observable<any>{ 
    return this.http.post(API_URLS.USER_DATA_POST_URL_NEW, recordData);
  }

   updateSubscribe(subscribed: string, slpId: number) {
    const requestData = {
      SUBSCRIBED: subscribed,
      SLPID: slpId
    };
    return this.http.put(API_URLS.UPDATE_SUBSCRIBED_SLPS, requestData);
  }

  updateFGrecord(recordData: any) {
    const requestData = {
      FG: recordData.FG,
      IVK: recordData.IVK,
      MATERIAL: recordData.MATERIAL,
      BUSINESSLINE: recordData.BUISNESSLINE,
      OFFICIALIPBNAME: recordData.OFFICIALIPBNAME,
    };
    return this.http.put(API_URLS.UPDATE_FG, recordData[0]);
  }

  updateBasicInfo(BUISNESSLINE:string, CSPROJECTMANAGER:string,CSEXPERT:string, CBLEXPERT:string,DUEDATE: Date,DATAFORMARRAY:any[], SLPESTIMATIONYR1:number, SLPESTIMATIONYR2:number, SLPESTIMATIONYR3:number, SLPESTIMATIONYR4:number, SLPESTIMATIONYR5:number, SLPHOURRATE: number, STATUS:any,SLPNAME:string, SLPID:number)  {
    const requestData = {
      BUISNESSLINE:BUISNESSLINE,
      CSPROJECTMANAGER:CSPROJECTMANAGER,
      CSEXPERT:CSEXPERT,
      CBLEXPERT:CBLEXPERT,
      DUEDATE: DUEDATE,
      DATAFORMARRAY:DATAFORMARRAY,
      SLPESTIMATIONYR1:SLPESTIMATIONYR1,
      SLPESTIMATIONYR2:SLPESTIMATIONYR2,
      SLPESTIMATIONYR3:SLPESTIMATIONYR3,
      SLPESTIMATIONYR4:SLPESTIMATIONYR4,
      SLPESTIMATIONYR5:SLPESTIMATIONYR5,
      SLPHOURRATE:SLPHOURRATE,
      STATUS: STATUS,
      SLPNAME: SLPNAME,
      SLPID:SLPID
    }

    return this.http.put(API_URLS.UPDATE_USER_INFO, requestData);
  }

deleteFGRecord(Fgname: string) {
  const url = `${API_URLS.FG_TABLE_DELETE}?Fg=${Fgname}`;
  return this.http.delete(url);
}
unsubscribeSLP(slpID: number) {
  const createdBy =  this.authService.getAccount()?.username ?? "";
  const url = `${API_URLS.UNSUBSCRIBE_SLP}?slpID=${slpID}&SubscribedBy=${createdBy}`;
  return this.http.delete(url);
}
deleteSlpProject(projectID: number) {
  const url = `${API_URLS.SLP_PROJECTDATA_DELETE}?projectID=${projectID}`;
  return this.http.delete(url);
}

deleteIVKRecord(Fgname: string, Ivk: string) {
  const url = `${API_URLS.FG_TABLE_IVK_DELETE}?Fg=${Fgname}&Ivk=${Ivk}`;
  return this.http.delete(url);
}
}
