import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public showUnauthorizedMessage = false;
  public readmeOss = false;
  private readonly _destroying$ = new Subject<string>();

  constructor(
    public authService: AuthService,
    private readonly route: ActivatedRoute,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
  ) {

    localStorage.removeItem('user1');
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.showUnauthorizedMessage = params['error'];
      });

    this.clearLocalStorage();

    if (localStorage.getItem('permissions') == null) {
      this.authService.getPermissions().subscribe(res => {
        if (res != null) {
          localStorage.setItem('permissions', JSON.stringify(res));
        }
      });
    }

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLogin();
      })

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe(() => {
        this.setLogin();
        this.router.navigate(['/home'], { relativeTo: this.route })
        window.location.reload();
      })
  }

  public clicking() {
    this.authService.login();
  }

  private setLogin() {
    this.checkAndSetActiveAccount();
  }

  ngOnDestroy(): void {
    this._destroying$.next(' ');
    this._destroying$.complete();
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  toggleReadmeOSS() {
    this.readmeOss = !this.readmeOss;
  }

  private clearLocalStorage() {
    localStorage.removeItem('user');
  }
}