import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataTransformationService {

  constructor() {}

  transformDataToUpper(data: any[]): any[] {
    const newData: any = [];
    data.forEach((item: any) => {
      const newItem: any = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const newKey = key.toUpperCase();
          newItem[newKey] = item[key];
        }
      }
      newData.push(newItem);
    });
    return newData;
  }

  transformComplexDataToUpper(data: any): any {
    if (Array.isArray(data)) {
      return data.map(item => this.transformComplexDataToUpper(item));
    } else if (typeof data === 'object' && data !== null) {
      const newData: any = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const newKey = key.toUpperCase();
          newData[newKey] = this.transformComplexDataToUpper(data[key]);
        }
      }
      return newData;
    }
    return data;
  }

  transformKeysToUppercase(data: { [key: string]: any[] }): { [key: string]: any[] } {
    const transformedData: { [key: string]: any[] } = {};

    Object.keys(data).forEach(key => {
      transformedData[key.toUpperCase()] = data[key].map(item => {
        const newItem: { [key: string]: any } = {};
        Object.keys(item).forEach(itemKey => {
          newItem[itemKey.toUpperCase()] = item[itemKey];
        });
        return newItem;
      });
    });

    return transformedData;
  }

}
