import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { SqlServiceService } from 'src/app/core/sql-service.service';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';

interface FGModel {
  FG: string;
  IVK: string;
  MATERIAL: string;
}

@Component({
  selector: 'app-functionalgroup',
  templateUrl: './functionalgroup.component.html',
  styleUrls: ['./functionalgroup.component.scss']
})
export class FunctionalgroupComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private sqlServiceService: SqlServiceService,
    private router: Router,
    private roleService: RoleService
  ) { }
  showFGNameError: boolean = true;
  isModalOpen = false;
  uniqueFG: any[] = [];
  showSuccessDialog = false;
  showAddPartDialog = false;
  showErrorDialogFlag = false;
  showDuplicateErrorDialog = false;
  showEditPartDialog = false;
  businessLineSelected : boolean = false;
  showDuplicateError = false;
  showExistingError = false;
  duplicateErrorMessage = '';
  ExistingErrorMessage = '';
  renameFGForm: FormGroup;
  showRenameFGDialog = false;
  loadingaddpart : boolean = false;
  businessLineArray : string[]=[];
  selectedDropdownItem: string;
  slpbusinessline: string;
  tableData: any[] = [];
  fgForm: FormGroup;
  todeletegroup : string = "";
  todeleteivk :string = "";
  openConfirmDeleteModal : boolean = false;
  addPartForm: FormGroup;
  editPartForm: FormGroup;
  currentGroup: string;
  isSaveButtonDisabled = true;
  savedData: any[] = [];
  ipbBoolean : true;
  disablesaveforeditmodal : boolean = false;
  loadingforsaveaddpart : boolean = false;
  loadingforsaveaddFG : boolean = false;

  loading : boolean = false;
  loadingforedit : boolean = false;
  loadingforsave : boolean = false;
  initialButtonStates = {
    saveDisabled: true,
    addMorePartsDisabled: true
  };
  initialButtonStatesForAddFG = {
    saveDisabled: true,
    addMorePartsDisabled: true 
  };
  validationStatus: boolean[] = [];
  validationStatusForAddFG: boolean[] = [];

  showDeleteFGmodal : boolean = false;
  showRenameFGSuccessModal : boolean = false;
  FGTOdelete :string = "";
  disabledSaveOnMaterialName : boolean = false;

  ngOnInit(): void {
    this.initializeForm();
    this.initializeBusinessLineArray();
  }

  initializeForm() {
    this.renameFGForm = this.fb.group({
      newFGname: ['', Validators.required]
    });

    this.fgForm = this.fb.group({
      FGname: [''],
      materials: this.fb.array([this.createMaterialGroup()])
    });

    this.addPartForm = this.fb.group({
      materials: this.fb.array([this.createMaterialGroup()])
    });

    this.editPartForm = this.fb.group({
      materials: this.fb.array([this.createMaterialGroup()])
    });
  }

  initializeBusinessLineArray() {
    this.businessLineArray = this.roleService.getBusinessLines();
  }
  
  updateSelectedBusinessLine(selectedBusinessLineValue: string) {
    this.sqlServiceService.getUniqueFG(selectedBusinessLineValue).subscribe((response: any) => {
      this.uniqueFG = response;
    });
    this.slpbusinessline = selectedBusinessLineValue;
    this.businessLineSelected = true;
    this.tableData = [];
  }

  createMaterialGroup(): FormGroup {
    return this.fb.group({
      materialId: ['', Validators.required],
      materialName: ['', Validators.required],
      materialNameEditable: [true]  // Add this control to manage editability
    });
  }
  editFGName(group: string): void {
    this.currentGroup = group;
    this.renameFGForm.patchValue({ newFGname: group });
    this.showRenameFGDialog = true;
  }

  closeRenameFGModal(): void {
    this.showRenameFGDialog = false;
  }

  onRenameFGSubmit(): void {
    if (this.renameFGForm.valid) {
        const newFGname = this.renameFGForm.get('newFGname')?.value;
        this.sqlServiceService.updateFGName(this.currentGroup, newFGname).subscribe(
            (response) => {
                this.uniqueFG = this.uniqueFG.map(fg => fg === this.currentGroup ? newFGname : fg);
                this.currentGroup = newFGname;
                this.closeRenameFGModal();
                this.updateTableData(newFGname);
                this.showRenameFGSuccessModal= true;
            },
            (error) => {
                console.error('Error updating FG name:', error);
            }
        );
    }
}

  get materials() {
    return this.fgForm.get('materials') as FormArray;
  }

  get addPartMaterials() {
    return this.addPartForm.get('materials') as FormArray;
  }
  get editPartMaterials() {
    return this.editPartForm.get('materials') as FormArray;
  }
  openModal() {
    this.isModalOpen = true;
    this.validationStatusForAddFG = [];
    this.validationStatusForAddFG.push(false);
    this.initializeButtonStatesForAddFG();
    this.showFGNameError = true;
  }

  closeModal() {
    this.isModalOpen = false;
    this.fgForm.reset();
    this.materials.clear();
    this.materials.push(this.createMaterialGroup());
    this.clearError();
  }

  addMaterial() {
    this.materials.push(this.createMaterialGroup());
    this.validationStatusForAddFG.push(false); // Add initial validation status
    this.updateButtonStatesForAddFG();
  }

  removeMaterial(index: number) {
    this.materials.removeAt(index);
    this.validationStatusForAddFG.splice(index, 1); // Remove validation status
    this.updateButtonStatesForAddFG();
    this.clearError();
  }

  async checkMaterial(index: number) {
    const materialGroup = this.materials.at(index) as FormGroup;
    const materialId = materialGroup.get('materialId')?.value;
    let exists: boolean = false;

    try {
        this.loading = true;
        exists = await this.sqlServiceService.checkIfIvkExistsInServicePartsTable(materialId).toPromise();
    } catch (error) {
        console.error("Error occurred during the API call", error);
    } finally {
        this.loading = false;
    }
    const materialNameControl = materialGroup.get('materialName');

    if (exists) {
      const materialName = await this.sqlServiceService.getMaterialNameData(materialId).toPromise();
      materialGroup.get('materialName')?.setValue(materialName.spMatText);
      materialGroup.get('materialNameEditable')?.setValue(false);
      this.disabledSaveOnMaterialName = false;
    } else {
      this.disabledSaveOnMaterialName = true;
      materialNameControl?.setValue('');
      materialNameControl?.setErrors({ invalidMaterialName: true });
      materialNameControl?.setValidators([
          Validators.required,
          Validators.pattern(/.+/)
      ]);
      materialGroup.get('materialNameEditable')?.setValue(true);
      materialNameControl?.markAsTouched();
      materialNameControl?.markAsDirty();
      materialNameControl?.updateValueAndValidity();
  }
    this.validationStatusForAddFG[index] = true;
    this.updateButtonStatesForAddFG();
  }

  onMaterialNameInput(index: number): void {
    const materialGroup = this.addPartMaterials.at(index) as FormGroup;
    const materialNameControl = materialGroup.get('materialName');
  
    if (materialNameControl?.hasError('invalidMaterialName') || materialNameControl) {
      materialNameControl.markAsUntouched();
      materialNameControl.setErrors(null);
      materialNameControl.updateValueAndValidity();
      this.updateButtonStates();
    }
  }

  onMaterialNameInputForAddFG(index: number): void {
    const materialGroup = this.materials.at(index) as FormGroup;
    const materialNameControl = materialGroup.get('materialName');
  
    if (materialNameControl?.hasError('invalidMaterialName') || materialNameControl) {
      materialNameControl.markAsUntouched();
      materialNameControl.setErrors(null);
      materialNameControl.updateValueAndValidity();
      this.updateButtonStatesForAddFG();
    }
  }
  
  
  async checkMaterialForPartForm(index: number) {
    const materialGroup = this.addPartMaterials.at(index) as FormGroup;
    const materialId = materialGroup.get('materialId')?.value;
    let exists: boolean = false;

    try {
        this.loadingaddpart = true;
        exists = await this.sqlServiceService.checkIfIvkExistsInServicePartsTable(materialId).toPromise();
    } catch (error) {
        console.error("Error occurred during the API call", error);
    } finally {
        this.loadingaddpart = false;
    }

    const materialNameControl = materialGroup.get('materialName');

    if (exists) {
        const materialName = await this.sqlServiceService.getMaterialNameData(materialId).toPromise();
        materialNameControl?.setValue(materialName.spMatText);
        materialGroup.get('materialNameEditable')?.setValue(false);
        this.disabledSaveOnMaterialName = false;
    } 
    else 
    {
      this.disabledSaveOnMaterialName = true;
        materialNameControl?.setValue('');
        materialNameControl?.setErrors({ invalidMaterialName: true });
        console.log('Set invalidMaterialName error:', materialNameControl?.errors);
        materialNameControl?.setValidators([
            Validators.required,
            Validators.pattern(/.+/)
        ]);
        materialGroup.get('materialNameEditable')?.setValue(true);
        materialNameControl?.markAsTouched();
        materialNameControl?.markAsDirty();
        materialNameControl?.updateValueAndValidity();
    }
    this.validationStatus[index] = true;
    this.updateButtonStates();
}
onFGInputChange() {
  const fgNameValue = this.fgForm.get('FGname')?.value;

  // Log the current value
  console.log(fgNameValue);

  // If the value is empty, set an error message
  if (!fgNameValue || fgNameValue.trim() === '') {
    this.fgForm.get('FGname')?.setErrors({ empty: true });
    this.initialButtonStates.saveDisabled = true;
    this.isSaveButtonDisabled = true;
    this.showFGNameError = true;
  } else {
    // Clear the error if the input has a value
    this.fgForm.get('FGname')?.setErrors(null);
    this.initialButtonStates.saveDisabled = false;
    this.isSaveButtonDisabled = false;
    this.showFGNameError = false;
  }
}

updateButtonStates(): void {
  const allValidated = this.validationStatus.every(status => status === true);
  this.initialButtonStates.saveDisabled = !allValidated;
  this.initialButtonStates.addMorePartsDisabled = !allValidated;
}

initializeButtonStates(): void {
  this.initialButtonStates.saveDisabled = true;
  this.initialButtonStates.addMorePartsDisabled = true;
}

updateButtonStatesForAddFG(): void {
  const allValidated = this.validationStatusForAddFG.every(status => status === true);
  this.initialButtonStatesForAddFG.saveDisabled = !allValidated;
  this.initialButtonStatesForAddFG.addMorePartsDisabled = !allValidated;
}

initializeButtonStatesForAddFG(): void {
  this.initialButtonStatesForAddFG.saveDisabled = true;
  this.initialButtonStatesForAddFG.addMorePartsDisabled = true;
}


openAddPartModal(group:string): void {
  this.currentGroup = group;
  this.showAddPartDialog = true;
  this.validationStatus = [];
  this.validationStatus.push(false);
  this.initializeButtonStates();
}

  async checkMaterialForEditPartForm(index: number) {
    const materialGroup = this.editPartMaterials.at(index) as FormGroup;
    const materialId = materialGroup.get('materialId')?.value;
    let exists: boolean = false;
    try {
        this.loadingforedit = true;
        exists = await this.sqlServiceService.checkIfIvkExistsInServicePartsTable(materialId).toPromise();
    } catch (error) {
        console.error("Error occurred during the API call", error);
    } finally {
        this.loadingforedit = false;
    }

    if (exists) {
      const materialName = await this.sqlServiceService.getMaterialNameData(materialId).toPromise();
      materialGroup.get('materialName')?.setValue(materialName.spMatText);
      materialGroup.get('materialNameEditable')?.setValue(false);
    } else {
      materialGroup.get('materialName')?.setValue('');
      this.disablesaveforeditmodal = true;
      materialGroup.get('materialNameEditable')?.setValue(true);
    }
  }

  onmaterialInputChange(index: number): void {
    // Get the material name control for the current index
    const materialNameControl = this.editPartMaterials.at(index).get('materialName');
  
    // Log the current value of the material name input
    const materialNameValue = materialNameControl?.value;
    console.log(`Material Name at index ${index}: ${materialNameValue}`);
  
    // If the material name is empty, disable the save button
    if (!materialNameValue || materialNameValue.trim() === '') {
      this.disablesaveforeditmodal = true; // Disable save if the value is empty
    } else {
      this.disablesaveforeditmodal = false; // Enable save if the value is not empty
    }
  }
  
  navigateToHome(){
    this.router.navigate(['/home']);
  }
  async onSubmit() {
    if (this.fgForm.valid) {
      const formValue = this.fgForm.value;
      const materials = formValue.materials;
  
      // Check for duplicate material IDs in the materials array
      const materialIds = materials.map((material: any) => material.materialId);
      const duplicateMaterialIds = materialIds.filter((item: string, index: number) => materialIds.indexOf(item) !== index);
  
      if (duplicateMaterialIds.length > 0) {
        this.showDuplicateError = true;
        this.duplicateErrorMessage = `Cannot add IVK ${duplicateMaterialIds.join(', ')} more than once.`;
        this.isSaveButtonDisabled = true;
        return;
      }
  
      // Check if any material IDs already exist in the database
      const checkRequests = materials.map((material: any) =>
        this.sqlServiceService.checkifIVKpresent(material.materialId,this.slpbusinessline).toPromise()
      );
      this.loadingforsaveaddFG = true;
      try {
        const checkResponses = await Promise.all(checkRequests);
        const existingIVKs = materials.filter((_: any, index: number) => checkResponses[index] === true)
                                      .map((material: any) => material.materialId);
  
        if (existingIVKs.length > 0) {
          this.showExistingError = true;
          this.ExistingErrorMessage = `The following IVKs already exist in a functional group: ${existingIVKs.join(', ')}`;
          this.isSaveButtonDisabled = true;
          return;
        }
  
        const result: FGModel[] = await Promise.all(materials.map(async (material: any) => {
          let officialIPBName = '';
  
          if (!material.materialNameEditable) {
            const fetchedNameData = await this.sqlServiceService.getMaterialNameData(material.materialId).toPromise();
            officialIPBName = fetchedNameData.spMatText || '';
          }
  
          return {
            FG: formValue.FGname,
            IVK: material.materialId,
            MATERIAL: material.materialName,
            BUSINESSLINE: this.slpbusinessline,
            OFFICIALIPBNAME: officialIPBName
          };
        }));
  
        await this.sqlServiceService.addFGrecord(result).toPromise();
        this.uniqueFG.push(formValue.FGname);
        this.currentGroup = formValue.FGname;
        this.showSuccessDialog = true;
        this.closeModal();
        this.updateTableData(this.currentGroup);
      } catch (error) {
        console.error("Error occurred: ", error);
        this.showErrorDialog();
      }
      finally {
        // Stop the loading spinner once the operation completes
        this.loadingforsaveaddFG = false;
      }
    }
  }

  async onEditPartSubmit() {
    if (this.editPartForm.valid) {
      // Start the loading spinner
      this.loadingforsave = true;
  
      try {
        const materials = this.editPartForm.value.materials;
        const result: FGModel[] = await Promise.all(materials.map(async (material: any) => {
          let officialIPBName = '';
          if (!material.materialNameEditable) {
            const fetchedNameData = await this.sqlServiceService.getMaterialNameData(material.materialId).toPromise();
            if (fetchedNameData != null || fetchedNameData != undefined) {
              officialIPBName = fetchedNameData.spMatText || '';
            }
          }
          return {
            FG: this.currentGroup,
            IVK: material.materialId,
            MATERIAL: material.materialName,
            BUSINESSLINE: this.slpbusinessline,
            OFFICIALIPBNAME: officialIPBName
          };
        }));
  
        // API call to update the Functional Group record
        await this.sqlServiceService.updateFGrecord(result).toPromise();
  
        // Close modal, show success dialog, and update table data
        this.closeEditPartModal();
        this.showSuccessDialog = true;
        this.updateTableData(this.currentGroup);
      } catch (error) {
        console.error("Error updating Functional Group:", error);
      } finally {
        // Stop the loading spinner once the operation completes
        this.loadingforsave = false;
      }
    }
  }
  

  async onAddPartSubmit() {
    if (this.addPartForm.valid) {
      const materials = this.addPartForm.value.materials;
      // Check for duplicate material IDs in the materials array
      const materialIds = materials.map((material: any) => material.materialId);
      const duplicateMaterialIds = materialIds.filter((item: string, index: number) => materialIds.indexOf(item) !== index);
  
      if (duplicateMaterialIds.length > 0) {
        this.showDuplicateError = true;
        this.duplicateErrorMessage = `Cannot add IVK ${duplicateMaterialIds.join(', ')} more than once.`;
        this.isSaveButtonDisabled = true;
        return;
      }
  
      // Start the loading spinner for adding part
      this.loadingforsaveaddpart = true;
  
      const checkRequests = materials.map((material: any) =>
        this.sqlServiceService.checkifIVKpresent(material.materialId, this.slpbusinessline).toPromise()
      );
  
      try {
        const checkResponses = await Promise.all(checkRequests);
        const existingIVKs = materials
          .filter((_: any, index: number) => checkResponses[index] === true)
          .map((material: any) => material.materialId);
  
        if (existingIVKs.length > 0) {
          this.showExistingError = true;
          this.ExistingErrorMessage = `The following IVKs already exist in a functional group: ${existingIVKs.join(', ')}`;
          this.isSaveButtonDisabled = true;
          return;
        }
  
        const result: FGModel[] = await Promise.all(materials.map(async (material: any) => {
          let officialIPBName = '';
  
          if (!material.materialNameEditable) {
            const fetchedNameData = await this.sqlServiceService.getMaterialNameData(material.materialId).toPromise();
            officialIPBName = fetchedNameData.spMatText || '';
          }
  
          return {
            FG: this.currentGroup,
            IVK: material.materialId,
            MATERIAL: material.materialName,
            BUSINESSLINE: this.slpbusinessline,
            OFFICIALIPBNAME: officialIPBName
          };
        }));
  
        // API call to add the Functional Group record
        await this.sqlServiceService.addFGrecord(result).toPromise();
  
        // Refresh the table data without refreshing the page
        this.updateTableData(this.currentGroup);
  
        // Show success dialog and close the modal
        this.showSuccessDialog = true;
        this.closeAddPartModal();
      } catch (error) {
        console.error("Error occurred: ", error);
        this.showErrorDialog();
      } finally {
        // Stop the loading spinner once the operation completes
        this.loadingforsaveaddpart = false;
      }
    }
  }
  

  updateTableData(group: string) {
    this.currentGroup = group;
    this.sqlServiceService.getFGparts(group).subscribe((response: any) => {
      this.tableData = response;
    });
  }

  editPart(group: string, materialId: string, materialName: string) {
    this.currentGroup = group;
    this.editPartMaterials.clear();
    const materialGroup = this.fb.group({
    materialId: [materialId, Validators.required],
    materialName: [materialName, Validators.required],
    });

    this.editPartMaterials.push(materialGroup);
    this.showEditPartDialog = true;
    this.disablesaveforeditmodal = false;
  }



  openDeleteModal(group: string, ivk: string) {
    this.todeletegroup = group;
    this.todeleteivk = ivk;
    this.openConfirmDeleteModal = true;
  }

  deletePart() {
    let group = this.todeletegroup;
    let ivk = this.todeleteivk;
    this.sqlServiceService.deleteIVKRecord(group, ivk).subscribe(() => {
      this.updateTableData(group);
    });
    this.openConfirmDeleteModal = false;
  }

  closeAddPartModal() {
    this.showAddPartDialog = false;
    this.addPartForm.reset();
    this.addPartMaterials.clear();
    this.addPartMaterials.push(this.createMaterialGroup());
    this.clearError();
  }
  closeEditPartModal() {
    this.showEditPartDialog = false;
    this.editPartForm.reset();
    this.editPartMaterials.clear();
    this.editPartMaterials.push(this.createMaterialGroup());
    this.clearError();
  }
  addMaterialToPartForm() {
    this.addPartMaterials.push(this.createMaterialGroup());
    this.validationStatus.push(false); // Add initial validation status
    this.updateButtonStates();
  }

  removeMaterialFromPartForm(index: number) {
    this.addPartMaterials.removeAt(index);
    this.validationStatus.splice(index, 1); // Remove validation status
    this.updateButtonStates();
    this.clearError();
  }

  showErrorDialog() {
    this.showErrorDialogFlag = true;
    setTimeout(() => {
      this.showErrorDialogFlag = false;
    }, 3000);
  }

  deleteFGRecord() {
    let fgname = this.FGTOdelete;
    this.sqlServiceService.deleteFGRecord(fgname).subscribe(() => {
      this.uniqueFG = this.uniqueFG.filter(fg => fg !== fgname);
      if (this.currentGroup === fgname) {
        this.tableData = [];
      }
    });
    this.showDeleteFGmodal = false;
  }
  closedeleteFGmodal(){
    this.showDeleteFGmodal = false;
  }
  closerenameFGmodal(){
    this.showRenameFGSuccessModal = false;
  }
  opendeleteFGmodal(fgname: string){
    this.FGTOdelete = fgname;
    this.showDeleteFGmodal = true;
  }
  closeSuccessDialog() {
    this.showSuccessDialog = false;
  }
  closedialog() {
    this.openConfirmDeleteModal = false;
  }
  clearError() {
    this.showDuplicateError = false;
    this.duplicateErrorMessage = '';
    this.ExistingErrorMessage = '';
    this.isSaveButtonDisabled = false;
  }

  onDisablingValidateButton(index: number) {
    const materialGroup = this.materials.at(index) as FormGroup;
    return this.disableValidateCheck(materialGroup);
  }

  onDisablingValidateButtonforParts(index: number)
  {
    const materialGroup = this.addPartMaterials.at(index) as FormGroup;
  return this.disableValidateCheck(materialGroup);
  }

  disableValidateCheck(materialGroup:FormGroup): boolean{
    const materialId = materialGroup.get('materialId')?.value;
    if(materialId) 
    {
      return false;
    }
    return true;
  }

  onMaterialNameInputChange(index: number) {
    const materialGroup = this.materials.at(index) as FormGroup;
    this.materialIdAndNameCheck(materialGroup);
  }

  onMaterialNameInputChangeForParts(index: number) {
    const materialGroup = this.addPartMaterials.at(index) as FormGroup;
    this.materialIdAndNameCheck(materialGroup);
   
  }

  materialIdAndNameCheck(materialGroup: FormGroup): void{
    const materialId = materialGroup.get('materialId')?.value;
    const materialName = materialGroup.get('materialName')?.value;
 
    if(materialId && materialName) 
    {
      this.disabledSaveOnMaterialName = false;
    }
    else 
    {
      this.disabledSaveOnMaterialName = true;
    }
  }
}
