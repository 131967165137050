import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/constants';
import { SlpProjectData } from 'src/app/models';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-slp-card',
  templateUrl: './slp-card.component.html',
  styleUrls: ['./slp-card.component.scss']
})

export class SLPCardComponent implements OnInit {

  constructor(private roleService: RoleService) { }

  @Input() slpData: SlpProjectData;
  @Input() flag: string;
  @Output() updateSlptData = new EventEmitter<number>();
  @Output() updateSlptDataAfterDelete = new EventEmitter<number>();
  @Output() projectViewtData = new EventEmitter<SlpProjectData>();

  date: string;
  statusText: string;
  currentDate: Date = new Date();
  showDelete: boolean;
  showConfirmationDlg: boolean;
  showCreateSlpSuccessDialog: boolean;

  
  ngOnInit() {
    this.date = this.formatDate(this.slpData.DUEDATE);
    this.setProjectStatusText();
    this.setDeleteButtonStatus();    
  }

  convertToDate(date: string | Date): Date {
    let dateObj: Date;
    if (typeof date === 'string') {
      dateObj = new Date(date);
    } else {
      dateObj = date;
    }
    return dateObj
  }

  setProjectStatusText() {
    const { STATUS, DUEDATE } = this.slpData;
    this.statusText = STATUS;
  
    if (STATUS === Constants.PROJECT_STATUS.IN_WORK && this.convertToDate(DUEDATE) < new Date()) {
      this.statusText =  Constants.PROJECT_STATUS.DELAYED;
    }
  }

  formatDate(date: string | Date): string {
    const dateObj = this.convertToDate(date);
    if (!(dateObj instanceof Date) || isNaN(dateObj.getTime())) {
      return '';
    }
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  setDeleteButtonStatus() {
    this.currentDate = new Date();
    const dateObj = this.convertToDate(this.slpData.DUEDATE);
    if (dateObj < this.currentDate) {
      this.showDelete = false;
    }
    else {
      this.showDelete = true;
    }
    this.showDelete = this.roleService.isDeveloperOrProjectManager() && this.showDelete;
  }

  onSubscribe() {
    this.updateSlptData.emit(this.slpData.SLPID);
  }

  onProjectView() {
    this.projectViewtData.emit(this.slpData);
  }

  deleteItem() {
    this.showConfirmationDlg = true;
  }

  cancelToggle() {
    this.showConfirmationDlg = false;
  }

  confirmToggle() {
    this.updateSlptDataAfterDelete.emit(this.slpData.SLPID);
    this.showConfirmationDlg = false;
    this.showCreateSlpSuccessDialog = true;
  }

  closeCreateSlpDialog() {
    this.showCreateSlpSuccessDialog = false;
  }

}
