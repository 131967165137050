import { Component, Input, SimpleChanges} from '@angular/core';
import * as echarts from 'echarts';
import { EChartsOption, PieSeriesOption} from 'echarts';
import { SlpProjectStatus } from 'src/app/models';

@Component({
  selector: 'app-state-chart',
  templateUrl: './state-chart.component.html',
  styleUrls: ['./state-chart.component.scss']
})

export class StateChartComponent {
  @Input() subscribedSlpStatus: SlpProjectStatus;
  chartInstance!: echarts.ECharts;
  echartOptions: EChartsOption = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',  
      right: '10px',       
      top: 'center',
      textStyle: {
        fontSize: 14,
        fontWeight: 'bold' 
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['50%', '90%'],
        center: ['35%', '50%'], 
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            formatter: (params: any) => {
              const value = params.value || 0;
              return `${params.name}: ${value}\n Total Subscribed Projects: ${ this.subscribedSlpStatus.totalCount}`;
            },
            fontSize: 12,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        color: ["#5470c6", "#91cc75", "#7D7D7D", "#ee6666"],
        data: []
      }
    ]
  };

  ngOnInit(): void {
    if (!this.chartInstance) { 
      const chartDom = document.getElementById('projectStatePieChart')!;
      if (chartDom) {
        this.chartInstance = echarts.init(chartDom);
        this.chartInstance.setOption(this.echartOptions);
      }
    }
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['subscribedSlpStatus'] && this.chartInstance) {
      this.updateChartData();
    }
  }

  updateChartData(): void {
    const chartData: PieSeriesOption['data'] = [
      { value: this.subscribedSlpStatus.inWorkCount, name: 'In Work' },
      { value: this.subscribedSlpStatus.releasedCount, name: 'Released' },
      { value: this.subscribedSlpStatus.completedCount, name: 'Completed' },
      { value: this.subscribedSlpStatus.delayedCount, name: 'Delayed' }
    ];

    this.chartInstance.setOption({
      series: [
        {
          label: {
            emphasis: {
              label: {
                formatter: (params: any) => {
                  const value = params.value || 0;
                  return `${params.name}: ${value}\n Total Subscribed Projects: ${ this.subscribedSlpStatus.totalCount}`;
                },
              }
            },
          },
          data: chartData
        }
      ]
    });
  }
}
