import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { TokenService } from './TokenService';

@Injectable({ 
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = false;

  constructor(
    private readonly msalService: MsalService,
    private readonly httpClient: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private tokenService: TokenService) { }

  get IsloggedIn()
  {
    return this.msalService?.instance?.getAllAccounts().length > 0;
  }

  login() {
    if(!this.IsloggedIn && !environment.isE2E){
      if (this.msalGuardConfig.authRequest){
        this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }else{
        this.router.navigate(['/home/'])
    }
  }

  logout() {
    this.msalService.logout();
  }

  getAccount() {
    return this.msalService.instance.getActiveAccount();
  }
  
}