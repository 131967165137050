import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../services';

@Injectable()
export class CustomHeadersInterceptor implements HttpInterceptor {

  constructor(private msalService: MsalService, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.IsloggedIn)
      return next.handle(request);

    //**** need to check error */
    // const account = this.msalService.instance.getActiveAccount();
    // if(account != null){
    //   const siemens_gid = account.idTokenClaims['siemens_gid'].toString();
    //   request = request.clone({
    //     setHeaders: { 'Siemens-Gid': siemens_gid }
    //   });
    // }


    return next.handle(request);
  }
}
