import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, concat, Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  currentVersion$: Observable<string>;
  isUpdateAvailable$: Observable<boolean>;

  constructor(
    private readonly appRef: ApplicationRef,
    private readonly swUpdate: SwUpdate,) {

    if (swUpdate.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      everySixHoursOnceAppIsStable$.subscribe(() => {
          swUpdate.checkForUpdate().then(updateAvailable => {
            if(updateAvailable){
              console.log('A new version is available.');
            }else{
              console.log('Already on the latest version');
            }
          }).catch(err => {
            console.error('Failed to check for updates:', err);
          });
      });      
    }      
  }

  checkForClientUpdates() {
    if (!this.swUpdate.isEnabled) {
      return
    }
    this.handleVersionUpdates();
    this.handleUnrecoverable();    
  }

  private handleVersionUpdates() {
    this.swUpdate.versionUpdates.subscribe((event) => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          console.log('New version detected.');
          this.isUpdateAvailable$ = of(true)
          break;
        case 'VERSION_READY':
          console.log('New version ready to install.');
          this.confirmAndUpdate();
          this.currentVersion$ = of(event.latestVersion.hash)
          this.isUpdateAvailable$ = of(false)
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log('New version installation failed.');
          this.isUpdateAvailable$ = of(true)
          break;
        case 'NO_NEW_VERSION_DETECTED':
          this.currentVersion$ = of(event.version.hash)
          this.isUpdateAvailable$ = of(false)
          console.log(' No new version')
          break;
      }
    });
  }

  private confirmAndUpdate() {
    //  document.location.reload()
  }

  private handleUnrecoverable() {
    this.swUpdate.unrecoverable.subscribe(event => {
      alert(
        'An error occurred that we cannot recover from:\n' + event.reason + '\n\nPlease reload the page.'
      );
    });
  }
}
