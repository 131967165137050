import { environment } from '../../environments/environment';

const ApiBaseUri = environment.apiBaseUrl;

export class Api {
    // static readonly Typeahead = {
    //     MaterialNumber: ApiBaseUri + 'api/v1/data/material',
    //     Country: ApiBaseUri + 'api/v1/data/country',
    //     functionalLocation: ApiBaseUri + 'api/v1/data/funclocation',
    //     ServicePartner: ApiBaseUri + 'api/v1/data/servicepartner',
    //     Division: ApiBaseUri + 'api/v1/data/division',
    // };
    static readonly Token = {
        GenerateToken: ApiBaseUri + 'api/token?hideLoadingIndicator',
        RefreshToken: ApiBaseUri + 'api/token/refresh?hideLoadingIndicator',
    }
    // static readonly Search = {
    //     ConfigItem: ApiBaseUri + 'api/v1/search',
    //     EqupimentOverview: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/overview',
    //     EquipmentDetails: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/details',
    //     ProductInfo: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/product',
    //     UpdateEquipment: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id,
    //     EquipmentSalesInfo: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/salesinfo',
    //     EquipmentWarrantyInfo: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/warranty',
    //     EquipmentComments: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/comments',
    //     PostComment: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/comments',
    //     EquipmentRemoteConnectionInfo: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/remoteconnection',
    //     EquipmentHqContracts: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/contracts',
    //     SendToRu: ApiBaseUri + 'api/v1/equipment/sendtoru',
    //     getRelationships: (id: string) =>  ApiBaseUri + 'api/v1/equipment/' + id + '/relationship',
    //     deleteUpstream: (equipmentId: string, id: string) =>  ApiBaseUri + 'api/v1/equipment/' + equipmentId + '/relationship/' + id + '?type=Up',
    //     deleteDownstream: (equipmentId: string, id: string) =>  ApiBaseUri + 'api/v1/equipment/' + equipmentId + '/relationship/' + id + '?type=Down',
    //     editRelationships: (id: string, rowid: string) =>  ApiBaseUri + 'api/v1/equipment/' + id + '/relationship/' + rowid,
    //     AuditLogs: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/auditlogs',
    //     getLicenseDetail: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/license',
    //     getAttachments: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/attachments',
    //     getServiceabilityInfo: (id: string) => ApiBaseUri + 'api/v1/equipment/' + id + '/serviceability',
    //     materialSearch: ApiBaseUri + 'api/v1/search/materialmaster',
    //     createEquipment: ApiBaseUri + 'api/v1/equipment',
    //     servicePartnerSearch: ApiBaseUri + 'api/v1/search/servicepartner',
    //     getServicePartnerContact: (id: string) => ApiBaseUri + 'api/v1/servicepartner/' + id + '/contact',
    //     getCommunicationInfo: (id: string) => ApiBaseUri + 'api/v1/servicepartner/' + id + '/communicationinformation',
    // };

    // static readonly RelationshipLayout = {
    //     selectedLayout: ApiBaseUri + 'api/v1/layout/relationship',
    //     defaultLayout: ApiBaseUri + 'api/v1/layout/relationship/default',
    //     resetLayout: ApiBaseUri + 'api/v1/layout/relationship/reset'
    // };

    // static readonly GridLayout = {
    //     getDefaultLayout: (type: string) => ApiBaseUri + 'api/v2/grid/default?type=' + type,
    //     createUserLayout: (type: string) => ApiBaseUri + 'api/v2/grid?type=' + type,
    //     getUserLayout: (type: string) => ApiBaseUri + 'api/v2/grid?type=' + type,
    //     deleteUserLayout: (type: string) => ApiBaseUri + 'api/v2/grid?type=' + type
    // };
    
    // static readonly Material = {
    //     general: (id: string) => ApiBaseUri + 'api/v1/materialmaster/' + id + '/general',
    //     overview: (id: string, hideLoadingIndicator = false) => ApiBaseUri + 'api/v1/materialmaster/' + id + '/overview' + (hideLoadingIndicator ? '?hideLoadingIndicator' : ''),
    //     updateMaterial: (id: string) => ApiBaseUri + 'api/v1/materialmaster/' + id,
    //     otherDetails: (id: string) => ApiBaseUri + 'api/v1/materialmaster/' + id + '/others',
    //     reporting: (id: string) => ApiBaseUri + 'api/v1/materialmaster/' + id + '/reporting',
    //     auditLogs: (id: string) => ApiBaseUri + 'api/v1/materialmaster/' + id + '/auditlogs'
    // };

    // static readonly ServicePartner = {
    //     overview: (id: string,hideLoadingIndicator = false) => ApiBaseUri + 'api/v1/servicepartner/' + id + '/overview' + (hideLoadingIndicator ? '?hideLoadingIndicator' : ''),
    //     communicationinformation: (id: string) => ApiBaseUri + 'api/v1/servicepartner/' + id + '/communicationinformation',
    //     debitordata: (id: string) => ApiBaseUri + 'api/v1/servicepartner/' + id + '/debitordata',
    //     updateServicePartner: (id: string) => ApiBaseUri + 'api/v1/servicepartner/' + id,
    //     auditLogs: (id: string) => ApiBaseUri + 'api/v1/servicepartner/' + id + '/auditlogs',
    // };

    // static readonly ImportWizard = {
    //     templates: ApiBaseUri + 'api/v1/importwizard/templates',
    //     history: ApiBaseUri + 'api/v1/importwizard/history?status=',
    //     saveTemplate: ApiBaseUri + 'api/v1/importwizard/template',
    //     tableData: ApiBaseUri + 'api/v1/importwizard/tables',
    //     getTemplateData: (id: string) => ApiBaseUri + 'api/v1/importwizard/template/' + id,
    //     upload: (id: string|number) => ApiBaseUri + 'api/v1/importwizard/'+id+'/upload',
    //     inProgress: ApiBaseUri + 'api/v1/importwizard/status/inprogress',
    //     ignoreStatus: (id: string) => ApiBaseUri + 'api/v1/importwizard/status/ignore/' + id,
    //     refreshStatus: (fileId: string) => ApiBaseUri + 'api/v1/importwizard/status/' + fileId,
    //     process: (fileId: string) => ApiBaseUri + 'api/v1/importwizard/process/' + fileId,
    //     schedule: ApiBaseUri + 'api/v1/importwizard/schedule',
    //     downloadExcel:(id:number,type:string)=>ApiBaseUri + `api/v1/importwizard/template/${id}/download?type=${type}`
    // };

    // static readonly MassUpdate = {
    //     defaultfields: ApiBaseUri + 'api/v1/massupdate/defaultfields',
    //     caption: ApiBaseUri + 'api/v1/massupdate/fields?query=',
    //     massupdate: ApiBaseUri + 'api/v1/massupdate',
    //     countErrorLogBulkUpdate: ApiBaseUri + 'api/v1/massupdate/errorlog/count',
    //     countOutboundBulkUpdate: ApiBaseUri + 'api/v1/massupdate/outbound/count',
    // }

    // static readonly ErrorInterface = {
    //     errorlog: ApiBaseUri + 'api/v1/search/errorlog',
    //     inputInterface: ApiBaseUri + 'api/v1/search/inputhist',
    //     outputInterface: ApiBaseUri + 'api/v1/search/output',
    //     errorlogMassUpdate: ApiBaseUri + 'api/v1/massupdate/errorlog',
    //     outboundMassUpdate: ApiBaseUri + 'api/v1/massupdate/outbound',
    //     errorlogDetails: (id: string) => ApiBaseUri + `api/v1/errorlog/${id}/details`,
    //     errorlogUpdate: (id: string) => ApiBaseUri + `api/v1/errorlog/${id}`,
    //     outboundDetails:(id: string, source: string) => ApiBaseUri + `api/v1/outbound/${id}/details?source=${source}`,
    //     inputInterfaceDetails:(id: string) => ApiBaseUri + `api/v1/inbound/${id}/details`,
    //     outboundUpdate: (id: string, source: string) => ApiBaseUri + `api/v1/outbound/${id}?source=${source}`,
    //     inputInterfaceUpdate:(id: string) => ApiBaseUri + `api/v1/inbound/${id}`,
    //     errorlogComparative:(id: string) => ApiBaseUri + `api/v1/errorlog/${id}/difference`,
    //     diffdetails:(id: string) => ApiBaseUri + `api/v1/errorlog/${id}/diffdetails`,
    //     inputInterfaceDiffDetails:(id: string) => ApiBaseUri + `api/v1/inbound/${id}/diffdetails`,
    //     inputInterfaceComparativeView: (id: string) => ApiBaseUri + `api/v1/inbound/${id}/difference`
    // }

    // static readonly Dropdown = {
    //     EqType: ApiBaseUri + 'api/v1/data/eqtype',
    //     EqStatus: ApiBaseUri + 'api/v1/data/eqstatus',
    //     EqSubType: ApiBaseUri + 'api/v1/data/eqsubtype',
    //     EqSubStatus:(filter: boolean = false, status: string = null) => ApiBaseUri + `api/v1/data/eqsubstatus?filter=${filter}&status=${status}`,
    //     RuContractStatus: ApiBaseUri + 'api/v1/data/rucontractstatus',
    //     RuContractSubStatus: ApiBaseUri + 'api/v1/data/rucontractsubstatus',
    //     InhouseFlag: ApiBaseUri + 'api/v1/data/inhouseflags',
    //     DummyFlag: ApiBaseUri + 'api/v1/data/dummyflags',
    //     DismountingReasons: ApiBaseUri + 'api/v1/data/dismountingreasons',
    //     SalesReasons: ApiBaseUri + 'api/v1/data/salesreasons',
    //     location:(loc: string) => ApiBaseUri + `api/v1/data/location?code=${loc}`,
    //     serviceProvider2:(creditor: string) => ApiBaseUri + `api/v1/data/sp2?creditor=${creditor}`,
    // };
    // static readonly Auth = {
    //     permissions: ApiBaseUri + 'api/v1/permissions',
    //     roles: ApiBaseUri + 'api/v1/roles',
    // };

    // static readonly ExportCsv = {
    //     equipments: ApiBaseUri + 'api/v1/export/equipment',
    //     materialmaster: ApiBaseUri + 'api/v1/download/materialmaster',
    //     servicePartner: ApiBaseUri + 'api/v1/export/servicepartner',
    //     errorlog: ApiBaseUri + 'api/v1/export/errorlog',
    //     outbound: ApiBaseUri + 'api/v1/download/outbound',
    //     inputInterface: ApiBaseUri + 'api/v1/download/inputinterface',
    //     functionalLocation: ApiBaseUri + 'api/v1/download/functionallocation',
    //     country: ApiBaseUri + 'api/v1/download/country'
    // };

    // static readonly CIRelationship = {
    //     delete: (upstreamCI: string, downstreamCI: string) => ApiBaseUri + `api/v2/equipment/relationship/${upstreamCI}/${downstreamCI}`,
    //     create: ApiBaseUri + `api/v2/equipment/relationship`,
    //     bulkDelete: (id: string) => ApiBaseUri + `api/v1/equipment/${id}/relationship/bulkdelete`,
    //     bulkMove: (id: string) => ApiBaseUri + `api/v1/equipment/${id}/relationship/bulkmove`,
    //     searchBulkMove: ApiBaseUri + `api/v2/equipment/relationship/bulkmove`,
    //     allRelatedCIs: ApiBaseUri + `api/v2/equipment/relationship/all`,
    // };

    // static readonly FunctionalLocation = {
    //     search: ApiBaseUri + 'api/v1/search/location',
    //     addLocation: ApiBaseUri + 'api/v1/functionallocation',
    //     getDetails: (id: string) => ApiBaseUri + `api/v1/functionallocation/${id}/details`,
    //     saveDetails: (id: string) => ApiBaseUri + `api/v1/functionallocation/${id}`,
    // }

    // static readonly Country = {
    //     search: ApiBaseUri + 'api/v1/search/country',
    //     getCountryDetail:(id: string) => ApiBaseUri + `api/v1/country/${id}/details`,
    //     saveCountryDetails:(id: string) => ApiBaseUri + `api/v1/country/${id}`,
    //     addCountry: ApiBaseUri + 'api/v1/country',
    // }

    // static readonly Report = {
    //     LoginReport: ApiBaseUri + 'api/v1/reports/login/download',
    //     CSVExportReport: ApiBaseUri + 'api/v1/reports/csvexport/download'
    // }

    // static readonly InterfaceMonitor = {
    //     InterfaceStatus: ApiBaseUri + 'api/v1/monitor',
    // }    
}
