<div *ngIf="isLoading" class="loading-overlay">
    <div class="loading-bar">
        <sh-spinner label='Loading Base System Data' *ngIf="isLoading"></sh-spinner>
    </div>
</div>

<sh-page>
    <sh-grid fit-content>
        <sh-card columns="3" col-m="12" col-s="12">
            <app-create-slp-project-info-card [slpDetailsCardData]='slpDetailsCardData' [isreferenceSystemDisplay]='isreferenceSystemDisplay' columns="12" col-m="12"
                col-s="12"
                style="display: flex; height: 100px; background-color: #ebebebb9; padding: 20px;"></app-create-slp-project-info-card>
                
            <sh-text size='super-header' columns="12" col-m="12" col-s="12">Your base system selections</sh-text>

            <app-create-slp-selection-card [baseSystemCard]='baseSystemCard' (baseSystemCardClickedEvent)="calculateSparePartTableData($event)" columns="12" col-m="12" col-s="12"
                *ngFor="let baseSystemCard of selectedBaseSystemDetailsDataArray"
                style="height: 120px; margin-top: 10px; margin-bottom: 20px;">
            </app-create-slp-selection-card>
        </sh-card>
        
        <sh-card columns="9" col-m="12" col-s="12">
            <sh-spinner label='Loading' *ngIf="!isLoading && FGLoadingSpinner && selectedBaseSystemCardId !== -1"></sh-spinner>
            <sh-text *ngIf="selectedBaseSystemCardId === -1" size='super-header'
                style="color: #EC6602; text-align: center;">Please
                Select the Base System to display Function Group Data</sh-text>
            <sh-grid fit-content *ngIf="!FGLoadingSpinner && selectedBaseSystemCardId !== -1">
                <div columns="12" style="display: flex; flex-direction: row;">
                    <sh-text size='super-header' columns="5" col-m="10" col-s="10" style="margin-bottom: 10px;">Function Group
                        List</sh-text>
                        <app-search-bar [searchAarray]="FGArray" [searchKeys]= "['FG','IVK', 'MATERIAL']"
                            (searchResults)="handleSearchResults($event)" columns="5" class="searchBarStyle"></app-search-bar>
                </div>
                
                <!-- Adjust the table style -->
                <table id="fgTable" columns="12" col-m="12" col-s="12" style="width: 99%; margin-top: 20px;" *ngIf="!isLoading && !FGLoadingSpinner && filteredFGArray.length !== 0">
                    <thead>
                        <tr>
                            <th rowspan="2">FG Select</th>
                            <th rowspan="2">FG</th>
                            <th rowspan="2">SP Select</th>
                            <th rowspan="2">IVK</th>
                            <th rowspan="4">Material</th>
                            <th rowspan="2">Work (h/spc)</th>
                            <th rowspan="2">Travel (h/spc)</th>
                            <th rowspan="2">Price (€/spc)</th>
                            <th rowspan="2">SPC</th>
                            <th rowspan="2">MTBF F.Rate</th>
                            <th rowspan="2">Influence %</th>
                            <th rowspan="2">Total Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let FGData of filteredFGArray; let i = index">
                            <td *ngIf="!isSameAsPrevious(i)">
                                <input type="checkbox" (change)="handleFGCheckboxSelection(FGData)" [checked]="isFGSelected(FGData['FG'])"
                                    [class.readonly-checkbox]="isFGSelected(FGData['FG']) && isReadOnly"
                                    [disabled]="!isFGSelected(FGData['FG']) && isReadOnly"/>
                            </td>
                            <td *ngIf="isSameAsPrevious(i)"></td>
                            <td>{{ FGData["FG"] }}</td>
                            <td>
                                <input type="checkbox" (change)="handleSPCheckboxSelection(FGData)" [checked]="FGData['CHECKED']"
                                    [class.readonly-checkbox]="FGData['CHECKED'] && isReadOnly"
                                    [disabled]="!FGData['CHECKED'] && isReadOnly" />
                            </td>
                            <td>{{ FGData["IVK"] }}</td>
                            <td>{{ FGData["MATERIAL"] }}</td>
                            <td>{{ FGData["WORK"] }}</td>
                            <td>{{ FGData["TRAVEL"] }}</td>
                            <td>{{ FGData["PRICE"] }}</td>
                            <td>{{ FGData["SPC"] }}</td>
                            <td>{{ FGData["MTBF"] }}</td>
                            <td>{{ FGData["INFLUENCE"] }}</td>
                            <td>{{ FGData["TOTALCOST"] }}</td>
                        </tr>
                    </tbody>
                </table>
            </sh-grid>
            <sh-empty-state icon='studies-reject' label='No FG Found'
            *ngIf="!isLoading && !FGLoadingSpinner && filteredFGArray.length === 0"></sh-empty-state>
        </sh-card>
        
    </sh-grid>
</sh-page>