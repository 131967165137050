import { Component, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { SqlServiceService } from 'src/app/core/sql-service.service';
import { SlpProjectDataFormComponent } from '../components/slp-project-data-form/slp-project-data-form.component';
import { BaseSystemsFormComponent } from '../components/base-systems-form2/base-systems-form2.component';
import { RefereneSystemsFormComponent } from '../components/referene-systems-form/referene-systems-form.component';
import { RoleService } from 'src/app/services/role.service';
import {
  SlpProjectData,
  SlpCostTableData,
  SlpDetailsCard,
  TargetSystemDataArray,
  BaseSystemDataCard,
  FunctionalGroupDataCard,
  FunctionalGroup,
  TargetSystemData,
  SLpNullTypeCostTable,
  NotificationCategory,
  YearListData,
  FunctionalGroupData
} from 'src/app/models';

@Component({
  selector: 'app-create-slp-form',
  templateUrl: './create-slp-form.component.html',
  styleUrls: ['./create-slp-form.component.scss']
})

export class CreateSlpFormComponent {
  isReadOnly: boolean = false;
  isLoading = false;
  isReloadModlaVisible = false;
  currentStep: number = 1;
  isFormInvalid: boolean = true
  showCreateSlpSuccessDialog: boolean = false;
  savedSlpFormData: SlpProjectData;
  savedTargetSystemFormData: TargetSystemDataArray;
  savedSlpCostTableData: SlpCostTableData;
  basesystems :string;
  isreloadclicked : boolean = false;
  isNextDisabled :boolean = false;
  slpDetailsCardData: SlpDetailsCard = {
    projectName: "",
    projectManager: "",
    hourRate: 0,
    M300: "",
    years: "",
    notifications: ""
  };
  selectedBaseSystemDetailsDataArray: BaseSystemDataCard[] = [];
  savedSelectedBaseSystemCardId: number = -1
  savedSelectedFGs: FunctionalGroup[] = [];
  selectedAllFGDataArray: FunctionalGroupDataCard[] = [];
  selectedUniqueFGDataArray: FunctionalGroupDataCard[] = [];
  responseSlpCostTableData: SLpNullTypeCostTable
  savedNotificationCategoriesArray: NotificationCategory[] = [];
  savedYearList: YearListData[] = []
  savedFgDataCache: Map<string, any[]>  = new Map<string, any[]>();
  savedSumIBDataCache: Map<string, number> = new Map<string, number>();
  savedFgInfoMap: Map<number, FunctionalGroupData[]> = new Map<number, FunctionalGroupData[]>();
  savedSelectedFGPartsCardsId: number = -1
  savedSelectedFGCardData: { cardId: number, name: string, IVK: string } = { cardId: -1, name: '', IVK: '' }
  savedShowFGDataId: string = ''
  isSLPID: number = 0;
  storeProcedureInputFieldUpdates: any = {
    SLPHOURRATE: false, 
    BASESYSTEMS: false, 
    YEARS: false, 
    NOTIFICATIONCATEGORY: false
  };
  @ViewChild(SlpProjectDataFormComponent, { static: false }) slpProjectDataFormComponent: SlpProjectDataFormComponent;
  @ViewChild(BaseSystemsFormComponent, { static: false }) baseSystemsFormComponent: BaseSystemsFormComponent;
  @ViewChild(RefereneSystemsFormComponent, { static: false }) referenceSystemsFormComponent: RefereneSystemsFormComponent;

  constructor(
    private router: Router,
    private roleService: RoleService,
    private sqlServiceService: SqlServiceService) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      const stateData = navigation?.extras.state?.["savedSlpFormData"];
      this.savedSlpFormData = stateData
      this.isSLPID = this.savedSlpFormData.SLPID;
      this.setTargetSystemFormData()
      this.setSlpCostTableData()
    }
    this.isReadOnly =  !this.roleService.isDeveloperOrProjectManager();
  }

  setTargetSystemFormData() {
    const systemIvkArray = this.savedSlpFormData.SLPSYSTEMIVK.split(',')
    const systemNameArray = this.savedSlpFormData.SLPSYSTEMNAME.split(',')
    const targetSystemDataArray: TargetSystemData[] = []
    systemIvkArray.forEach((ivk, index) => {
      const targetSystemData: TargetSystemData = { SLPSYSTEMIVK: ivk, SLPSYSTEMNAME: systemNameArray[index] ? systemNameArray[index] : '' }
      targetSystemDataArray.push(targetSystemData)
    })
    this.savedTargetSystemFormData = { TARGETSYSTEMDATARRAY: targetSystemDataArray }
  }

  setSlpCostTableData(): void {
    const IBYearsArray = this.savedSlpFormData.IBYEARS.split(',')
    const HourlyRateYearArray = this.savedSlpFormData.SLPHOURRATEYEARS.split(',')
    const PartCostIncreaseYearArray = this.savedSlpFormData.PARTCOSTINCREASEYEARS.split(',')
    const ProductivityMeasureYearArray = this.savedSlpFormData.PRODUCTIVITYMEASUREYEARS.split(',')

    const parseYearValue = (value: string) => (value !== 'null' ? Number(value) : null);

    this.responseSlpCostTableData = {
      IBRelease: this.savedSlpFormData.IB,
      IBYear1: parseYearValue(IBYearsArray[0]),
      IBYear2: parseYearValue(IBYearsArray[1]),
      IBYear3: parseYearValue(IBYearsArray[2]),
      IBYear4: parseYearValue(IBYearsArray[3]),
      IBYear5: parseYearValue(IBYearsArray[4]),

      HourlyRateRelease: this.savedSlpFormData.SLPHOURRATE,
      HourlyRateYear1: parseYearValue(HourlyRateYearArray[0]),
      HourlyRateYear2: parseYearValue(HourlyRateYearArray[1]),
      HourlyRateYear3: parseYearValue(HourlyRateYearArray[2]),
      HourlyRateYear4: parseYearValue(HourlyRateYearArray[3]),
      HourlyRateYear5: parseYearValue(HourlyRateYearArray[4]),

      PartCostIncreaseRelease: this.savedSlpFormData.PARTCOSTINCREASE,
      PartCostIncreaseYear1: parseYearValue(PartCostIncreaseYearArray[0]),
      PartCostIncreaseYear2: parseYearValue(PartCostIncreaseYearArray[1]),
      PartCostIncreaseYear3: parseYearValue(PartCostIncreaseYearArray[2]),
      PartCostIncreaseYear4: parseYearValue(PartCostIncreaseYearArray[3]),
      PartCostIncreaseYear5: parseYearValue(PartCostIncreaseYearArray[4]),

      ProductivityMeasureRelease: this.savedSlpFormData.PRODUCTIVITYMEASURE,
      ProductivityMeasureYear1: parseYearValue(ProductivityMeasureYearArray[0]),
      ProductivityMeasureYear2: parseYearValue(ProductivityMeasureYearArray[1]),
      ProductivityMeasureYear3: parseYearValue(ProductivityMeasureYearArray[2]),
      ProductivityMeasureYear4: parseYearValue(ProductivityMeasureYearArray[3]),
      ProductivityMeasureYear5: parseYearValue(ProductivityMeasureYearArray[4])
    };
  }
  
  async saveSlpProjectFormData(slpId: number, isDataReloaded: boolean= false) {
    await this.slpProjectDataFormComponent.onSlpProjectDataFormSubmit(slpId, isDataReloaded);
    this.savedSlpFormData = this.slpProjectDataFormComponent.SlpProjectDataForm.getRawValue()
    this.setSlpCardDetails(this.savedSlpFormData)
    this.savedTargetSystemFormData = this.slpProjectDataFormComponent.TargetSystemDataForm.getRawValue()
    this.savedSlpCostTableData = this.slpProjectDataFormComponent.CostTableForm.getRawValue()
    this.selectedBaseSystemDetailsDataArray = this.slpProjectDataFormComponent.selectedBaseSystemsArray
    this.savedNotificationCategoriesArray = this.slpProjectDataFormComponent.notificationCategoriesArray
    this.savedYearList = this.slpProjectDataFormComponent.yearsList
  }

  async getCheckedFGsFromStoredProcedureTable() {
    if(this.isSLPID == 0){
      const recentlySavedSlpId: number = await lastValueFrom(this.sqlServiceService.getRecentSavedSlpProjectId()) ?? -1;
      await this.sqlServiceService.changeStateInStoredProcedureTable(this.savedSelectedFGs, recentlySavedSlpId);
    }
    else{
      await this.sqlServiceService.changeStateInStoredProcedureTable(this.savedSelectedFGs,this.isSLPID);
    }
  }

  saveBaseSystemsFormData() {
    this.selectedAllFGDataArray = this.baseSystemsFormComponent.getAllFGValues();
    this.selectedUniqueFGDataArray = this.baseSystemsFormComponent.getUniqueFGValues();
    this.savedFgInfoMap = this.baseSystemsFormComponent.fgInfoMap;
    this.savedSelectedBaseSystemCardId = this.baseSystemsFormComponent.selectedBaseSystemCardId;
    this.savedSelectedFGs = this.baseSystemsFormComponent.selectedFGs;
    this.savedSelectedFGPartsCardsId = this.baseSystemsFormComponent.selectedFGPartsCardsId;
    this.savedFgDataCache = this.baseSystemsFormComponent.fgDataCache;
    this.savedSumIBDataCache = this.baseSystemsFormComponent.sumIBDataCache;
    this.getCheckedFGsFromStoredProcedureTable()
  }

  extractNotifiactionCategoryCodes(data: string): string {
    const parts = data.split(',');
    const codes = parts.map(part => part.split('-')[0].trim());
    return codes.join(',');
  }

  get isHourlyRateReloadCondition(): boolean {
    const { SLPHOURRATE, BASESYSTEMS, YEARS, NOTIFICATIONCATEGORY } = this.storeProcedureInputFieldUpdates;
    return SLPHOURRATE && !BASESYSTEMS && !YEARS && !NOTIFICATIONCATEGORY;
  }
  
  async onReloadClick() {
    this.isreloadclicked = true;
    this.isNextDisabled = true;
    this.isLoading = true;
    await this.saveSlpProjectFormData(this.isSLPID, true);
    const notifiationCategories = this.extractNotifiactionCategoryCodes(this.savedSlpFormData.NOTIFICATIONCATEGORY);
    try {
      if (this.isHourlyRateReloadCondition) {
        await this.sqlServiceService.updateServicePartsIbaseResultsOnHourlyRateChange(this.isSLPID, Number(this.savedSlpFormData.SLPHOURRATE));
      } else {
        await this.sqlServiceService.callStoredProcForExistingSLP(this.isSLPID, Number(this.savedSlpFormData.SLPHOURRATE), this.savedSlpFormData.BASESYSTEMS, this.savedSlpFormData.YEARS, notifiationCategories);
      }
    } catch (error) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
      this.isReloadModlaVisible = true;
    }
    this.resetStoreProcedureInputFieldUpdates();
  }

  onHourlyRateUpdated(value: number): void {
    if (this.isSLPID === 0) { return };

    const HourlyRateRelease = Number(this.savedSlpFormData.SLPHOURRATE)

    if (HourlyRateRelease !== value) {
      this.storeProcedureInputFieldUpdates.SLPHOURRATE = true;
      this.isNextDisabled = true;
    }else {
      this.storeProcedureInputFieldUpdates.SLPHOURRATE = false;
      this.updateReloadAndNextStatus();
    }
  }

  checkFieldStringInEquality(savedFieldValue: string, updatedInputValue: string): boolean {
    const savedFieldValueArray = savedFieldValue?.split(',').map(item => item.trim()).sort((a, b) => a.localeCompare(b));
    const updatedFieldValueArray = updatedInputValue?.split(',').map(item => item.trim()).sort((a, b) => a.localeCompare(b));
    return savedFieldValueArray?.join() !== updatedFieldValueArray?.join();
  }

  async onSlpFormValuesUsedForStoreProcedureUpdated(event: { field: string, value: string }) {
    if (this.isSLPID === 0) { return };

    const savedFieldValue = (this.savedSlpFormData as any)[event.field];

    if (this.checkFieldStringInEquality(savedFieldValue, event.value)) {
      this.isNextDisabled = true;
      this.storeProcedureInputFieldUpdates[event.field] = true;
    } else {
      this.storeProcedureInputFieldUpdates[event.field] = false;
      this.updateReloadAndNextStatus();
    }    
  }

  resetStoreProcedureInputFieldUpdates(): void { 
    for (const field in this.storeProcedureInputFieldUpdates) {
      this.storeProcedureInputFieldUpdates[field] = false; 
    }
    this.isNextDisabled = false;
  }

  updateReloadAndNextStatus(): void {
    let fieldsChanged = false; 
   
    for (const field in this.storeProcedureInputFieldUpdates) {
      if (this.storeProcedureInputFieldUpdates[field]) {
        fieldsChanged = true; 
        break; 
      }
    }
    this.isNextDisabled = fieldsChanged;
  }

  async setDataWhenCurrentStepIsOne() {
    const id = this.isSLPID !== 0 ? this.isSLPID : 0;
    await this.saveSlpProjectFormData(id);
    this.isNextDisabled = true;
  }
  
  async onCreateSlpStepperNextClick(step: any = null) {
    if (this.currentStep === 1) {
      await this.setDataWhenCurrentStepIsOne();
    }
    this.currentStep = step ?? this.currentStep;
    if (this.currentStep == 2) {
      this.saveBaseSystemsFormData();
    }
    
    if (this.currentStep < 3) {
      this.currentStep++;
    }
  }

  saveReferenceSystemsFormState(): void {
    this.savedSelectedFGPartsCardsId = this.referenceSystemsFormComponent.selectedFGPartsCardsId;
    const selectedFGCardData = this.referenceSystemsFormComponent.selectedUniqueFGDataArray[this.savedSelectedFGPartsCardsId];
    this.savedSelectedFGCardData = selectedFGCardData
        ? { cardId: this.savedSelectedFGPartsCardsId, name: selectedFGCardData.FG, IVK: selectedFGCardData.IVK }
        : { cardId: -1, name: '', IVK: '' };
    this.savedShowFGDataId = this.referenceSystemsFormComponent.showFGDataId;
    this.referenceSystemsFormComponent.onPreviousButtonClick();
}

  async onCreateSlpStepperPreviousClick(step?: number) {
    if (this.currentStep === 3) {
      this.saveReferenceSystemsFormState();
      if (this.referenceSystemsFormComponent.isFunctionalGroupInputData && !this.referenceSystemsFormComponent.isReferenceSystemDataSubmitted) {
        return
      }
      this.currentStep--;
    }else if (this.currentStep === 2) {
      this.savedSelectedFGs = this.baseSystemsFormComponent.selectedFGs
      this.savedFgDataCache = this.baseSystemsFormComponent.fgDataCache
      this.savedSumIBDataCache = this.baseSystemsFormComponent.sumIBDataCache
      this.savedSelectedBaseSystemCardId = this.baseSystemsFormComponent.selectedBaseSystemCardId
      this.getCheckedFGsFromStoredProcedureTable()
      this.isSLPID = await lastValueFrom(this.sqlServiceService.getRecentSavedSlpProjectId()) ?? 0;
      this.isNextDisabled = false;
      this.currentStep--;
    }
    this.currentStep = step ?? this.currentStep;
  }

  closeCreateSlpDialog() {
    this.showCreateSlpSuccessDialog = false;
  }

  closeSuccessDialog() {
    this.isReloadModlaVisible = false;
  }

  setSlpCardDetails(slpProjectFormData: any) {
    this.slpDetailsCardData.projectName = slpProjectFormData.SLPNAME
    this.slpDetailsCardData.projectManager = slpProjectFormData.CSPROJECTMANAGER
    this.slpDetailsCardData.hourRate = slpProjectFormData.SLPHOURRATE
    this.slpDetailsCardData.M300 = slpProjectFormData.DUEDATE
    this.slpDetailsCardData.years = slpProjectFormData.YEARS
    this.slpDetailsCardData.notifications = slpProjectFormData.NOTIFICATIONCATEGORY
  }

  onUpdateFgInfoMap(fgInfoMap: { cardId: number, savedFgInfoArray: FunctionalGroupData[] }) {
    this.savedFgInfoMap.set(fgInfoMap.cardId, fgInfoMap.savedFgInfoArray)
  }

  onFormValidityChange(isInvalid: boolean) {
    this.isFormInvalid = isInvalid;
  }

  onReferenceSystemSelected(isSelected: boolean) {
    this.isNextDisabled = !isSelected;
  }
}
