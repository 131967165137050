import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const PermissionGuard: CanActivateFn = (route) => {
  const authService = inject(AuthService); 
  const router = inject(Router); 
  
  if (authService.hasAccessPermissions()) {
    return true;
  } else {
    router.navigate(['/access-denied']);
    return false;
  }
};
