import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public functionalGroupChange: Subject<any> = new Subject<any>();
  public groups: {[key:string]: any[]} = {};
  //targetFormArray$: Observable<any[]> = this.targetFormArraySubject.asObservable();
  constructor() {}
}
