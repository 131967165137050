import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgShuiModule } from '@shui/angular';

import 'src/app/shared/utils/ShuiComponent';

import { AccessDeniedComponent } from './access-denied/access-denied.component';

@NgModule({
  declarations: [
    AccessDeniedComponent
  ],
  imports: [
    CommonModule,
    NgShuiModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class AccessDeniedModule { }
