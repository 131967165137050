<sh-grid>
    <sh-card label="All SLPs" columns="12">
        <div slot='functions' columns="4" class="searchBarStyle">
            <app-search-bar [searchAarray]="allSlpData" [searchKeys]="constants.ALL_SLP_DATA_SEARCH_KEYS"
                (searchResults)="handleSearchResults($event)" columns="4"></app-search-bar>
        </div>
        <sh-spinner label='Loading All SLPs' *ngIf="isLoading"></sh-spinner>
        <sh-grid style="overflow-y: scroll; padding: 5px 0px;" *ngIf="!isLoading">
            <div *ngFor="let slp of filteredAllSlpData" columns="3" class="slpDataCardStyle">
                <app-slp-card [slpData]="slp" [flag]="slp['SUBSCRIBED'] === 'Subscribe' ? 'Unsubscribe' : 'Subscribe' "
                    (updateSlptData)="handlingSubscribed($event)" (projectViewtData)="onViewAllSlpCardClick($event)"
                    (updateSlptDataAfterDelete)="handlingUpdateAllSlpProjectAfterDelete($event)"></app-slp-card>
            </div>
        </sh-grid>
        <sh-empty-state icon='studies-reject' label='No Slp Found'
            *ngIf="!isLoading && filteredAllSlpData.length === 0"></sh-empty-state>
    </sh-card>
</sh-grid>