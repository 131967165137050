import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  @Input() searchAarray: any[] = []; 
  @Input() searchKeys: string[] = []; 
  @Output() searchResults = new EventEmitter<any[]>(); 

  searchControl = new FormControl('');
  filteredItems: any[] = [];
  private searchSubject = new Subject<string>();

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      debounceTime(1000)
    ).subscribe(searchTerm => {
      this.performSearch(searchTerm ?? '');
    });
  }

  private performSearch(searchTerm: string): void {
    if (searchTerm.length < 2) {
      this.searchResults.emit(this.searchAarray);
      return;
    }

    this.filteredItems = this.searchAarray.filter(item =>
      this.searchKeys.some(key =>
        item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    this.searchResults.emit(this.filteredItems);
  }

  onSearchInput(): void {
    const searchTerm = this.searchControl.value;
    this.searchSubject.next(searchTerm ?? '');
  }

  clearSearch(): void {
    this.searchControl.setValue('');
    this.searchResults.emit(this.searchAarray);
  }

}