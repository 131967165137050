import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SqlServiceService } from '../../core/sql-service.service';
import { ErrorService } from 'src/app/shared/layouts';
import { forkJoin, lastValueFrom } from 'rxjs';
import { RoleService } from '../../services/role.service';
import { SlpProjectData, SlpProjectStatus } from 'src/app/models';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
})

export class MonitoringComponent {

  allSlpData: SlpProjectData[] = [];
  isNavigationButtonEnabled: boolean = true;
  subscribedSlpIdSet: Set<number> = new Set();
  subscribedSlpData: SlpProjectData[] = [];
  subscribedSlpStatus: SlpProjectStatus = { inWorkCount: 0, releasedCount: 0, completedCount: 0, delayedCount: 0, totalCount: 0 };
  isLoading = true;

  constructor(
    private router: Router,
    private sqlServiceService: SqlServiceService,
    private errorService: ErrorService,
    private roleService: RoleService
  ) { 
    this.isNavigationButtonEnabled = this.roleService.isDeveloperOrProjectManager();
  }

  ngOnInit() {
    
    forkJoin({
      allSlpData: this.sqlServiceService.getSLPUserData(),

      subscribedSlpIds: this.sqlServiceService.getSubscribedSlps(),
      subscribedSlpStatus: this.sqlServiceService.getSubscribedSlpStatus()
    }).subscribe({
      next: ({ allSlpData, subscribedSlpIds, subscribedSlpStatus }) => {
        this.allSlpData = allSlpData;
        this.subscribedSlpIdSet = new Set(subscribedSlpIds);
        this.subscribedSlpStatus = subscribedSlpStatus
        this.getSubscribedSlpDataArray();
        this.isLoading = false;
      }
    });

  }

  removeSlpFromAllSlpData(slpId: number): void {
    this.allSlpData = this.allSlpData.filter(slp => slp.SLPID !== slpId);
  }

  async handlingUpdateAllSlpProjectAfterDelete(slpId: number) {
    await lastValueFrom(this.sqlServiceService.deleteSlpProject(slpId));

    const slpIndex = this.allSlpData.findIndex(slp => slp.SLPID === slpId);
    if (slpIndex === -1) {
      return;
    }

    if (this.subscribedSlpIdSet.has(slpId)) {
      this.updateSubscriptionState(slpId, slpIndex, "Unsubscribe", -1);
    }

    this.removeSlpFromAllSlpData(slpId);
  }

  async updateSubscribedEvents(slpId: number) {
    await lastValueFrom(this.sqlServiceService.toggleSubscribedSlps(slpId));

    const slpIndex = this.allSlpData.findIndex(slp => slp.SLPID === slpId);
    if (slpIndex === -1) {
      return;
    }
    
    let subscriptionAction = 'Subscribe';
    let deltaCount = 1;

    if(this.subscribedSlpIdSet.has(slpId)){
      subscriptionAction = 'Unsubscribe';
      deltaCount = -1;
    }
    this.updateSubscriptionState(slpId, slpIndex, subscriptionAction, deltaCount);
  }

  async updateUnsubscribe(slpId: number) {
    const slpIndex = this.allSlpData.findIndex(slp => slp.SLPID === slpId);
    if (slpIndex === -1) {
      return;
    }
    
    let subscriptionAction = 'Unsubscribe';
    let deltaCount = -1;
    this.updateSubscriptionState(slpId, slpIndex, subscriptionAction, deltaCount);
  }
  

  updateSubscriptionState(slpId: number, slpIndex: number, subscriptionAction: string, deltaCount: number) {
    if (subscriptionAction === 'Unsubscribe') {
      this.subscribedSlpIdSet.delete(slpId);
      this.subscribedSlpData = this.subscribedSlpData.filter(slp => slp.SLPID !== slpId);
    } else {
      this.subscribedSlpIdSet.add(slpId);
      this.subscribedSlpData.push(this.allSlpData[slpIndex]);
    }
  
    this.updateAllSlpArray(slpIndex, subscriptionAction);
    this.updateSubscribedSlpStatus(slpIndex, deltaCount);
  }

  updateAllSlpArray(slpIndex: number, subscriptionStatus: string) {
    this.allSlpData[slpIndex].SUBSCRIBED = subscriptionStatus;
  }

  updateSubscribedSlpStatus(slpIndex: number, deltaCount: number) {
    const slpData = this.allSlpData[slpIndex];
    const slpDueDate = new Date(slpData.DUEDATE);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const newSubscribedSlpStatusCounts = { ...this.subscribedSlpStatus };
    
    if (slpData.STATUS === "In Work") {
      if (slpDueDate <= currentDate) {
        newSubscribedSlpStatusCounts.delayedCount += deltaCount;
      } else {
        newSubscribedSlpStatusCounts.inWorkCount += deltaCount;
      }
    } else if (slpData.STATUS === 'Released') {
      newSubscribedSlpStatusCounts.releasedCount += deltaCount;
    } else {
      newSubscribedSlpStatusCounts.completedCount += deltaCount;
    }
    
    newSubscribedSlpStatusCounts.totalCount += deltaCount;
    this.subscribedSlpStatus = newSubscribedSlpStatusCounts;
  }
  
  getSubscribedSlpDataArray() {
    this.allSlpData = this.allSlpData.map(slp => {
      if (this.subscribedSlpIdSet.has(slp.SLPID)) {
        this.subscribedSlpData.push(slp);
        return { ...slp, SUBSCRIBED: 'Subscribe' };
      } else {
        return { ...slp, SUBSCRIBED: 'Unsubscribe' };
      }
    });
  }

  navigateToCreateSLPPage() {
    if(this.isNavigationButtonEnabled) {
      this.router.navigate(['information']);
    }
  }

  navigateToFGPage() {
    if(this.isNavigationButtonEnabled) {
      this.router.navigate(['functionalgroup']);
    }
  }

}