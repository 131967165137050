import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseSystemDataCard, FunctionalGroupDataCard } from 'src/app/models';

@Component({
  selector: 'app-create-slp-selection-card',
  templateUrl: './create-slp-selection-card.component.html',
  styleUrls: ['./create-slp-selection-card.component.scss']
})
export class CreateSlpSelectionCardComponent {
  @Input() baseSystemCard?: BaseSystemDataCard;
  @Input() selectedFGcard?: FunctionalGroupDataCard;
  @Output() baseSystemCardClickedEvent = new EventEmitter<{matid: string, id: number}>()
  @Output() FGCardClickedEvent = new EventEmitter<{id: string, cardId: number}>()

  ngOnInit(): void {
    // Used to assign values to baseSystemcard and selectedFgCard. Don't remove. 
  }

  onBaseSystemCardClick(matid: string, id: number){
    this.baseSystemCardClickedEvent.emit({matid: matid, id: id})
  }

  onFGCardClick(id: string, cardId: number) {
    this.FGCardClickedEvent.emit({id: id, cardId: cardId})
  }
}
