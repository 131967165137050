<sh-page>
    <sh-splash-screen modal label="Login" version="SLP"
        image="url('/assets/images/SplashScreen_KeyVisual.jpg')">
        <span *ngIf="showUnauthorizedMessage">
            <h2 style="text-align: center; color: red"> Unauthorized access </h2>
            <h4 style="text-align: center; color: rgb(224, 44, 44);">- You do not have the required GAMA roles assigned
                to access this application. </h4>
        </span>

        <sh-empty-state icon="user-avatar">
            <sh-button slot="footer" label="Login" (click)="this.clicking()"></sh-button>
        </sh-empty-state>

        <sh-text slot="info" size='body-2' color="secondary"><a (click)="toggleReadmeOSS()">Privacy Notice</a></sh-text>
        <app-version-info slot="info"></app-version-info>
        <sh-text slot="info" size="body-2" color="secondary">© 2020 Siemens Healthcare GmbH, all rights reserved.</sh-text>
        <sh-modal *ngIf="readmeOss" sticky visible id="readme-oss" label='Privacy Notice & Open Source Software'
            modal-width='60%' modal-height='90%'>
            <sh-icon slot="functions" button size="s" (click)="toggleReadmeOSS()" icon="cancel">
            </sh-icon>
            <app-readme-oss></app-readme-oss>
        </sh-modal>
    </sh-splash-screen>
</sh-page>