<sh-page>
  <sh-access-bar label="Service LifeCycle Planning" slot="access">
    <sh-tabs slot="tabs">
      <sh-tab-item label="Home" active style="margin-right: 25px" [routerLink]="['/', 'home']"></sh-tab-item>
    </sh-tabs>
    <sh-user-identifier id="user-identifier" slot="user" [attr.name]="this.profile" [attr.info]="this.username"
      [initials]="this.profileInitial">
    </sh-user-identifier>
    <sh-icon button icon="menu" slot="left"></sh-icon>
  </sh-access-bar>

  <sh-grid>
    <div columns="12">
      <router-outlet></router-outlet>
    </div>
  </sh-grid>
</sh-page>

<sh-popover target="user-identifier" position="bottom-left" class="userpopover">

  <ng-container *ngIf="this.authService.IsloggedIn(); then logoutMenu; else loginMenu">
  </ng-container>
  <ng-template #loginMenu>
    <sh-menu-item label="Login" (click)="this.authService.login()"></sh-menu-item>
  </ng-template>
  <ng-template #logoutMenu>
    <sh-text size="header-2">{{this.profile}}</sh-text>
    <sh-text size="body-1">{{this.username}}</sh-text>
    <sh-divider></sh-divider>
    <sh-menu-item label="Sign Out" class="padding-top-8" (click)="this.authService.logout()" icon="logoff">
    </sh-menu-item>
  </ng-template>
</sh-popover>


<app-error></app-error>
<app-general-confirmation-popup></app-general-confirmation-popup>