import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { Permission } from 'src/app/models/Permission';
import { User } from 'src/app/models/User';
import { environment } from 'src/environments/environment';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = false;
  private Auth = {
    permissions: environment.apiBaseUrl + 'api/permissions',
    roles: environment.apiBaseUrl + 'api/roles',
};

  constructor(
    private readonly msalService: MsalService,
    private readonly httpClient: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private router: Router,
    private roleService: RoleService) { }
    
  
  IsloggedIn()
  {
    localStorage.setItem('user1', JSON.stringify(this.msalService?.instance?.getActiveAccount()?.username));
    localStorage.setItem('userRole',"RA115-G-GAMA_SLP_DEVELOPER");
    return this.msalService?.instance?.getAllAccounts().length > 0;
  }

  login() {
    if(!this.IsloggedIn && !environment.isE2E){
      if (this.msalGuardConfig.authRequest){
        this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    }else{
        this.router.navigate(['/home'])
    }
  }

  logout() {
    this.msalService.logout();
  }

  getAccount() {
    return this.msalService.instance.getActiveAccount();
  }

  getAllAccounts() {
    return this.msalService.instance.getAllAccounts();
  }

  getPermissions(): Observable<Permission[]>{
    return this.httpClient.get<Permission[]>(this.Auth.permissions);
  }

  hasAccessPermissions(): boolean {
    const rolesList = this.roleService.getRoles().filter(role => role !== '');
    return rolesList.length > 0;
  }
  
  getUserRoles(): Observable<User>{
    return this.httpClient.get<User>(this.Auth.roles);
  }
}
