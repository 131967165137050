<sh-modal class="toast" sticky [visible]="generalConfirmationService.askConfirmation" 
    id="general-confirmation-popup" [label]="generalConfirmationService.confirmationDetails?.title"
    icon='information' modal-height='200px' modal-width='330px'>
    <sh-icon slot="functions" button size="s" icon="cancel" (click)="dismiss()"></sh-icon>
    <div>
        <sh-text size='title-1'>{{generalConfirmationService.confirmationDetails?.message}}</sh-text>
    </div>   
    
    <sh-button color='secondary' slot='footer' label='No' (click)="dismiss()"></sh-button>
    <sh-button slot='footer' label='Yes' (click)="yes()"></sh-button>
</sh-modal>