import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../services/JwtService';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private jwtService: JwtService ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const siemens_gid = "TESTUSER";
        request = request.clone({
            setHeaders: { 'Siemens-Gid': siemens_gid, 'Authorization': `Bearer ${this.jwtService.getToken()}` }
        });

        return next.handle(request);
    }
}
