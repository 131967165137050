import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
//import { LoadingService } from 'src/app/shared/services';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {
  private totalRequests = 0;
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // dont show loading indicator when typeing in the search boxes
    if (request.url.indexOf('/data/') >= 0 ||
      /\/v1\/importwizard\/status\/\d+$/i.test(request.url) ||
      request.url.indexOf('hideLoadingIndicator') >= 0) {
      return next.handle(request)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        );
    }

    // if (this.totalRequests === 0) {
    //   this.loadingService.showLoading();
    // }
    this.totalRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        // if (this.totalRequests === 0) {
        //   this.loadingService.hideLoading();
        // }    
      })
    );
  }
}
