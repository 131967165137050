import { Injectable } from '@angular/core';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class RoleService {

  getRolesFromLocalStorage(): string {
    return localStorage.getItem(Constants.LOCAL_STORAGE_KEYS.LOGGED_IN_USER_ROLES) ?? '';
  }

  public getRoles(): string[] {
   return this.getRolesFromLocalStorage().split(',');
  }

  public isDeveloperOrProjectManager(): boolean { 
    const rolesArray = this.getRolesFromLocalStorage().split(',');
    return rolesArray.includes(Constants.ROLES.DEVELOPER) || rolesArray.includes(Constants.ROLES.PROJECTMANAGER);
  }

  public getBusinessLines(): string[] {
    const rolesString = this.getRolesFromLocalStorage();
    const businessLineList = rolesString.split(',').filter(role => role !== Constants.ROLES.DEVELOPER && role !== Constants.ROLES.PROJECTMANAGER);
    return businessLineList;
  }
}