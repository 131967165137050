import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
})
export class PrivacyNoticeComponent{
  constructor() { 
    // This is intentional
 }

}
