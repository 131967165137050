<div class="dropdown">
    <div class="search-box" >
        <sh-icon color="grey" icon="search" size="xs" style="width: 10%;">
        </sh-icon>

        <div class="input-wrapper">
            <input type="text" id="search-input" #SearchInputRef class="boxCSS"
               (input)="onSearchInput()" [formControl]="searchControl" placeholder="Search Systems..." />
        </div>

        <sh-icon color="#EC6602" icon="cancel" size="s" style="width: 10%;" (click)="clearSearch()">
        </sh-icon>
    </div>
</div>