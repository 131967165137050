import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private isError = false;
  private httpErrorResponse: HttpErrorResponse;

  get hasError(){
    return this.isError;
  }

  set hasError(value: boolean){
    this.isError = value;
  }

  set errorDetails(value: HttpErrorResponse){
    this.httpErrorResponse = value;
  }
  
  get errorDetails(){
    return this.httpErrorResponse;
  }
}
