import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralConfirmationService {

  askConfirmation = false;
  confirmationDetails: any;
  Close: Subject<any>;
  ConfirmEvent: Subject<any>;

  showConfirmation(title: string, message: string){
    this.Close = new Subject<any>();
    this.ConfirmEvent = new Subject<any>();
    this.askConfirmation = true;
    this.confirmationDetails = {
      title: title,
      message: message
    };
  }
}
