<sh-modal class="toast" sticky visible *ngIf="errorService.hasError" id="advance-search-filters"
    [label]="errorService.errorDetails.error.Title || 'Something went wrong'" icon='error' modal-height='220px'
    modal-width='350px'>
    <sh-icon slot="functions" button size="s" icon="cancel" (click)="onErrorModalClose()"></sh-icon>
    <div class="error">
        <sh-text size='header-1'>
            {{ '(' + (errorService.errorDetails.status) + ') ' + (errorService.errorDetails.statusText) }}
        </sh-text>
        <sh-text size='title-1'>{{ errorService.errorDetails.error.Message }}</sh-text>
    </div>
    <sh-button slot="footer" label="Close" (click)="onErrorModalClose()">
    </sh-button>
</sh-modal>