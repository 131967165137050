import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { NgxEchartsModule } from 'ngx-echarts';
import { environment } from 'src/environments/environment';
import { SharedComponent } from './shared/shared.component';
import { MonitoringComponent } from './modules/monitoring/monitoring.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { CommonModule, DatePipe } from '@angular/common';
import { NgShuiModule } from '@shui/angular';
import 'src/app/shared/utils/ShuiComponent';

import { AppRoutingModule } from './app-routing.module';
import { SLPCardComponent } from './shared/slp-card/slp-card.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SqlServiceService } from './core/sql-service.service';
import { ErrorService } from './shared/layouts';
import { CommonService } from 'src/app/shared/common.service'
import { LoginRoutingModule } from './modules/login/login-routing.module';
import { JwtAuthGuard } from './shared/guards/JwtAuthGuard';
import { JwtAuthComponent } from './shared/guards/JwtAuthComponent';
import { JwtInterceptor } from './core/interceptor/JwtInterceptor';
import { HttpErrorHandlerInterceptor } from './core/interceptor/http-error-handler.interceptor';
import { SharedModule } from './shared/shared.module';

import { CoreModule } from 'src/app/core/core.module';

import { StateChartComponent } from './modules/monitoring/components/state-chart/state-chart.component';
import { SubscribedSlpComponent } from './modules/monitoring/components/subscribed-slp/subscribed-slp.component';
import { AllSlpComponent } from './modules/monitoring/components/all-slp/all-slp.component';
import { CreateSlpModule } from './modules/planning/create-slp/create-slp.module';
import { AccessDeniedModule } from './modules/access-denied/access-denied.module';
import { FunctionalgroupComponent } from './modules/functionalgroup/functionalgroup.component';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export function MSALInstanceFactory(): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.auth0Client.clientId,
      authority: environment.auth0Client.authority,
      redirectUri: window.location.origin + "/login",
      postLogoutRedirectUri: window.location.origin + "/",
      navigateToLoginRequestUrl: true
    },
    cache:
    {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE,
    },
    system:
    {
      allowNativeBroker: false, // Disables native brokering support
    }
  })
}

/**
 * Configuring the AAD login type and api related permissions
 * @returns AAD Guard configuration
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/'
  }
}

/**
 * Interceptor configuration for the API calls
 * @returns 
 */

export function MSALInterceptorConfig(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map(
      [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        [environment.apiBaseUrl + 'api/permissions', null],
        [environment.apiBaseUrl, [environment.auth0Client.scope]],
      ]
    ),
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SharedComponent,
    MonitoringComponent,
    SLPCardComponent,
    FunctionalgroupComponent,
    StateChartComponent,
    SubscribedSlpComponent,
    AllSlpComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    PowerBIEmbedModule,
    HttpClientModule,
    LoginRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MsalModule.forRoot(MSALInstanceFactory(), MSALGuardConfigFactory(), MSALInterceptorConfig()),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    NgShuiModule,
    CoreModule,
    SharedModule,
    CreateSlpModule,
    AccessDeniedModule
  ],
  providers: [
    SqlServiceService,
    ErrorService,
    CommonService,
    DatePipe,
    !environment.isE2E ? {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    } : {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorHandlerInterceptor,
    //   multi: true
    // },
    MsalGuard, JwtAuthGuard],
  bootstrap: [AppComponent, !environment.isE2E ? MsalRedirectComponent : JwtAuthComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }