import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  ErrorComponent,
        // NavComponent,
         UnsavedModelComponent, 
        ConfirmationPopupComponent, GeneralConfirmationPopupComponent } from 'src/app/shared/layouts';
import { RouterModule } from '@angular/router';
import { PermissionDirective } from './directives/permission.directive';
//import { JsonSearchPipe } from './pipes/jsonsearch.pipe';
//import { NameSearchPipe } from './pipes/name-search.pipe';
// import { ColumnOptionsComponent } from './layouts/column-options/column-options.component';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
//import { EditPermissionDirective } from './directives/edit-permission.directive';
import { LoadingIndicatorInterceptor } from '../core/interceptor/loading-indicator.interceptor';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ClickedOutsideDirective } from './directives/clicked-outside.directive';
import { NgShuiModule } from '@shui/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchBarComponent } from './search-bar/search-bar.component';
import 'src/app/shared/utils/ShuiComponent';

@NgModule({
  declarations: [
    //AlertComponent,
    ErrorComponent,
    // NavComponent,
    UnsavedModelComponent,
    ConfirmationPopupComponent,
    GeneralConfirmationPopupComponent,
    HighlightPipe,
    ClickedOutsideDirective,
    SearchBarComponent
  ],
  imports: [
    CommonModule,
    NgShuiModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    //AlertComponent,
    ErrorComponent,
    // NavComponent,
    UnsavedModelComponent,
    ConfirmationPopupComponent,
    GeneralConfirmationPopupComponent,
    HighlightPipe,
    ClickedOutsideDirective,
    SearchBarComponent
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingIndicatorInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
