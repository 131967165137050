<head>
  <!-- Add this line in the head section of your HTML file -->
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
</head>
<div class="button-dropdown-container" style="display: flex; align-items: center; margin-left: 200px; margin-top: 10px;">
  <button (click)="navigateToHome()" style="height:20px;margin-right:20px;" class="buttons">
    <i class="fas fa-arrow-left"></i>
    Back
  </button>
<!-- Dropdown list -->
  <sh-dropdown label='Select Business Line' [value]='slpbusinessline' style="width:10%;">
    <sh-menu-item *ngFor="let businessLine of businessLineArray"
        label="{{businessLine}}"
        (click)="updateSelectedBusinessLine(businessLine)"></sh-menu-item>

  </sh-dropdown>
</div>
<div class="container" style="margin-top:25px;">
  <div class="functional-groups" style="box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); padding: 10px; border-radius: 5px;">
    <h2>
      Functional Groups
      <button class="add-button" *ngIf = "businessLineSelected" (click)="openModal()">
        <i class="fas fa-plus"></i>
      </button>
    </h2>
    <div *ngFor="let group of uniqueFG" [ngClass]="{'selected': group === currentGroup}" class="group-card" (click)="updateTableData(group)" 
         style="background-color: #f0f0f0; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); margin-bottom: 15px; padding: 10px; border-radius: 5px; cursor: pointer; height: 35px; display: flex;">
      <span>{{ group }}</span>
      <button (click)="editFGName(group)">
        <i class="fas fa-edit"></i>
      </button>
        <button (click)="openAddPartModal(group)">
          <i class="fas fa-plus"></i>
        </button>
        <button (click)="opendeleteFGmodal(group)">
          <i class="fas fa-trash-alt"></i>
        </button>
    </div>
  </div>
  <div class="container2">
  <h3>{{currentGroup}} Function Group Table</h3> 
  <div class="group-table" style="width: 96%; padding: 10px; float: right;">
    <table style="width: 100%;">
      <thead>
        <tr>
          <th>Material ID</th>
          <th>Material Text</th>
          <th>Official IPB Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of tableData">
          <td>{{ item.IVK }}</td>
          <td>{{ item.MATERIAL }}</td>
          <td>{{ item.OFFICIALIPBNAME }}</td>
          <td>
            <div class="button-dropdown-container" style="display: flex; align-items: center;">
              <button (click)="editPart(currentGroup,item.IVK, item.MATERIAL)">
                <i class="fas fa-edit"></i>
              </button>
              <button (click)="openDeleteModal(currentGroup, item.IVK)">
                <i class="fas fa-trash-alt"></i>
              </button>
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>

<!-- Add Success Dialog Here -->
<div *ngIf="showSuccessDialog" class="custom-dialog">
  <div class="custom-dialog-content">
    <h2>Success</h2>
    <p>Data added successfully</p>
    <button type="button" (click)="closeSuccessDialog()">OK</button>
  </div>
</div>

<!-- Modal for adding new functional group -->
<div *ngIf="isModalOpen" class="custom-dialog">
  <div class="custom-dialog-content">
    <h2 style="margin-bottom:10px;">Add New Functional Group</h2>
    <form [formGroup]="fgForm" (ngSubmit)="onSubmit()">
      <sh-spinner label='Searching for part' *ngIf="loading"></sh-spinner>
      <div style="margin-bottom:20px;">
        <label for="FGname">Functional Group Name</label>
        <input id="FGname" formControlName="FGname" type="text" (keyup)="onFGInputChange()"style="background-color: #f5f5f5;border:none;">
        <!-- Error message for Functional Group Name -->
        <div *ngIf="showFGNameError" style="color: red;">
          <small>Functional Group Name cannot be empty</small>
        </div>
      </div>

      <div formArrayName="materials">
        <div *ngFor="let material of materials.controls; let i=index" [formGroupName]="i" style="display: flex; align-items: center;">
          <div style="flex-basis: 40%; display: flex; align-items: center;">
            <label for="materialId" style="margin-right: 5px;">Material ID</label>
            <input id="materialId" formControlName="materialId" type="text" (keyup)="onMaterialNameInputChange(i)"
            style="margin-right: 20px;background-color: #f5f5f5;border:none;">
          </div>
          <div style="flex-basis: 60%; display: flex; align-items: center;">
            <label for="materialName" style="margin-right: 5px;">Material Name</label>
            <div style="display: flex; flex-direction: column; width: 100%;">
              <input id="materialName" formControlName="materialName" type="text" (keyup)="onMaterialNameInputChange(i)"
              [attr.disabled]="!material.get('materialNameEditable') ? true : null" 
              (blur)="onMaterialNameInputForAddFG(i)" 
              style="margin-right: 15px;background-color: #f5f5f5;border:none;margin-right:10px;">
              <div class="material-name-container">
                <div *ngIf="material.get('materialName')?.hasError('invalidMaterialName') || material.get('materialName')?.touched" class="material-name-error">
                  <small>Part not found. Please add a valid material name</small>
                </div>
              </div>
            </div>
          </div>
          <button type="button" (click)="removeMaterial(i)" *ngIf="materials.length > 1" style="margin-right: 10px;margin-top:-10px;">
            <i class="fas fa-minus"></i>
          </button>
          <button type="button" (click)="checkMaterial(i)" [disabled]="onDisablingValidateButton(i)" style="margin-right: 10px;margin-top:-10px;">
            Validate
          </button>
        </div>
      </div>
      <div *ngIf="showDuplicateError" style="margin-top:5px;margin-bottom:5px;color:red">{{duplicateErrorMessage}}</div>
      <div *ngIf="showExistingError" style="margin-top:5px;margin-bottom:5px;color:red">{{ExistingErrorMessage}}</div>
      <sh-spinner label='Saving Data' *ngIf="loadingforsaveaddFG"></sh-spinner>

      <button type="button" (click)="addMaterial()" [disabled]="initialButtonStatesForAddFG.addMorePartsDisabled || disabledSaveOnMaterialName" style="margin-top: 20px; margin-right: 10px;">
        Add more parts
      </button>
      <button type="submit" [disabled]="showFGNameError || initialButtonStatesForAddFG.saveDisabled || disabledSaveOnMaterialName" style="margin-top: 20px; margin-right: 10px;">Save</button>
      <button type="button" (click)="closeModal()" style="margin-top: 20px; margin-right: 10px;">Cancel</button>
    </form>
  </div>
</div>

<!-- Modal for adding parts to existing functional group -->
<div *ngIf="showAddPartDialog" class="custom-dialog">
  <div class="custom-dialog-content">
    <h2 style="margin-bottom:10px;">Add Part to Functional Group {{ currentGroup }}</h2>
    <form [formGroup]="addPartForm" (ngSubmit)="onAddPartSubmit()" style="margin-top:15px;">
      <sh-spinner label='Searching for part' *ngIf="loadingaddpart"></sh-spinner>
      <div formArrayName="materials">
        <div *ngFor="let material of addPartMaterials.controls; let i=index" [formGroupName]="i" style="display: flex; align-items: center;">
          
          <!-- Material ID Field -->
          <div style="flex-basis: 40%; display: flex; align-items: center;">
            <label for="materialId" style="margin-right: 5px;">Material ID</label>
            <input id="materialId" formControlName="materialId" type="text" (keyup)="onMaterialNameInputChangeForParts(i)"
             style="width: 100%; background-color: #f5f5f5; border: none;">
          </div>
          
          <!-- Material Name Field -->
          <div style="flex-basis: 60%; display: flex; align-items: center;">
            <label for="materialName" style="margin-right: 5px;">Material Name</label>
            <div style="display: flex; flex-direction: column; width: 100%;">
              <input id="materialName" formControlName="materialName" type="text" 
                     [attr.disabled]="!material.get('materialNameEditable') ? true : null" 
                     (keyup)="onMaterialNameInputChangeForParts(i)"
                     style="background-color: #f5f5f5; border: none;"
                     (blur)="onMaterialNameInput(i)">
              <div class="material-name-container">
                <div *ngIf="material.get('materialName')?.hasError('invalidMaterialName') || material.get('materialName')?.touched" class="material-name-error">
                  <small>Part not found. Please add a valid material name</small>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Buttons -->
          <button type="button" (click)="removeMaterialFromPartForm(i)" *ngIf="addPartMaterials.length > 1" style="margin-left: 10px;margin-top:-10px;">
            <i class="fas fa-minus"></i>
          </button>
          <button type="button" (click)="checkMaterialForPartForm(i)" [disabled]="onDisablingValidateButtonforParts(i)" style="margin-left: 10px;margin-top:-10px;">
            Validate
          </button>
        </div>
      </div>
      
      <div *ngIf="showDuplicateError" style="margin-top:5px; margin-bottom:5px; color:red;">
        {{duplicateErrorMessage}}
      </div>
      <div *ngIf="showExistingError" style="margin-top:5px;margin-bottom:5px;color:red">
        {{ExistingErrorMessage}}
      </div>
      <sh-spinner label='Saving Data' *ngIf="loadingforsaveaddpart"></sh-spinner>

      <button type="button" (click)="addMaterialToPartForm()" [disabled]="initialButtonStates.addMorePartsDisabled || disabledSaveOnMaterialName" style="margin-top: 20px; margin-right: 10px;">
        Add more parts
      </button>
      <button type="submit" [disabled]="disabledSaveOnMaterialName" style="margin-top: 20px; margin-right: 10px;">Save</button>
      <button type="button" (click)="closeAddPartModal()" style="margin-top: 20px; margin-right: 10px;">Cancel</button>
    </form>
  </div>
</div>


<!--Edit dialog for each material -->
<div *ngIf="showEditPartDialog" class="custom-dialog">
  <div class="custom-dialog-content">
    <h2 style="margin-bottom:10px;">Edit Part of Functional Group {{ currentGroup }}</h2>
    <form [formGroup]="editPartForm" (ngSubmit)="onEditPartSubmit()" style="margin-top:15px;">
      <sh-spinner label='Searching for part' *ngIf="loadingforedit"></sh-spinner>
      <div formArrayName="materials">
        <div *ngFor="let material of editPartMaterials.controls; let i=index" [formGroupName]="i" style="display: flex; align-items: center;">
          <label for="materialId" style="margin-right: 5px;">Material ID</label>
          <input id="materialId" formControlName="materialId" readonly type="text" style="margin-right: 20px;background-color: #f5f5f5;border:none;">
          <label for="materialName" style="margin-right: 5px;">Material Name</label>
          <input id="materialName" (keyup)="onmaterialInputChange(i)" formControlName="materialName" [attr.disabled]="!material.get('materialNameEditable') ? true : null" type="text" style="margin-right: 10px;background-color: #f5f5f5;border:none;">
          
          <button type="button" (click)="checkMaterialForEditPartForm(i)" style="margin-right: 10px;">
            Validate
          </button>
          <div class="material-name-container" *ngIf="disablesaveforeditmodal">
            <div class="material-name-error">
              <small>Part not found. 
                <br>Add valid material name</small>
            </div>
          </div>
        </div>
      </div>
      <sh-spinner label='Saving Data' *ngIf="loadingforsave"></sh-spinner>
      <button type="submit" [disabled]="disablesaveforeditmodal" style="margin-top: 20px; margin-right: 10px;">Save</button>
      <button type="button" (click)="closeEditPartModal()" style="margin-top: 20px; margin-right: 10px;">Cancel</button>
    </form>
  </div>
</div>

<!-- Modal for renaming functional group -->
<div *ngIf="showRenameFGDialog" class="custom-dialog">
  <div class="custom-dialog-content">
    <h2>Rename Functional Group {{ currentGroup }}</h2>
    <form [formGroup]="renameFGForm" (ngSubmit)="onRenameFGSubmit()">
      <div style="margin-bottom:20px;">
        <label for="FGname">New Functional Group Name</label>
        <input id="FGname" formControlName="newFGname" type="text" style="background-color: #f5f5f5;border:none;">
      </div>
      <button type="submit" style="margin-top: 20px; margin-right: 10px;">Save</button>
      <button type="button" (click)="closeRenameFGModal()" style="margin-top: 20px; margin-right: 10px;">Cancel</button>
    </form>
  </div>
</div>


<!-- Error Dialog -->
<div *ngIf="showErrorDialogFlag" class="custom-dialog">
  <div class="custom-dialog-content">
    <h2>Error</h2>
    <p>An error occurred. Please try again.</p>
    <button type="button" (click)="showErrorDialog()">OK</button>
  </div>
</div>
<!-- Error Dialog -->
<div *ngIf="openConfirmDeleteModal" class="custom-dialog">
  <div class="custom-dialog-content" >
    <h2>Are you sure you want to delete this material ?</h2>
    <button type="button" (click)="deletePart()" style="margin-right:20px;">Yes</button>
    <button type="button" (click)="closedialog()">Cancel</button>
  </div>
</div>

<div *ngIf="showDeleteFGmodal" class="custom-dialog">
  <div class="custom-dialog-content" >
    <h2>Are you sure you want to delete this Functional Group ?</h2>
    <button type="button" (click)="deleteFGRecord()" style="margin-right:20px;">Yes</button>
    <button type="button" (click)="closedeleteFGmodal()">Cancel</button>
  </div>
</div>

<div *ngIf="showRenameFGSuccessModal" class="custom-dialog">
  <div class="custom-dialog-content" >
    <h2>Functional Group renamed successfully</h2>
    <button type="button" (click)="closerenameFGmodal()">Ok</button>
  </div>
</div>