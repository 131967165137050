import { Constants } from "../constants";

export class Roles {
    public static readonly keyValueMap: { [key: string]: string } = {
      "8fb934c9-e3d0-4325-b36a-3ce1d734df0f": Constants.ROLES.AT,
      "2ad866be-6b60-4d25-bddd-0b2752e7589d": Constants.ROLES.CT,
      "563e505e-2e81-4bd9-8c35-db9778b045e7": Constants.ROLES.MR,
      "a032e53e-2f90-4f02-aef3-216ac2479002": Constants.ROLES.XP,
      "f220bdb7-8660-413d-a313-a00c9637d82e": Constants.ROLES.DNA,
      "ded332d6-96ca-44de-af97-54143dd38b3f": Constants.ROLES.DEVELOPER,
      "bed780c3-da3a-410f-9a43-d1ed564dd299": Constants.ROLES.PROJECTMANAGER
    };
  
    public static getValue(key: string): string | undefined {
      return this.keyValueMap[key];
    }
}