import { Component } from '@angular/core';

@Component({
  selector: 'app-readme-oss',
  templateUrl: './readme-oss.component.html',
  styleUrls: ['./readme-oss.component.scss']
})
export class ReadmeOSSComponent {

  constructor() { 
     // This is intentional
  }

}
