import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SqlServiceService } from 'src/app/core/sql-service.service';
import { SlpProjectData } from 'src/app/models';

@Component({
  selector: 'app-subscribed-slp',
  templateUrl: './subscribed-slp.component.html',
  styleUrls: ['./subscribed-slp.component.scss']
})
export class SubscribedSlpComponent {
  @Input() subscribedSlpData: SlpProjectData[] = []
  @Input() isLoading = true;
  @Output() unsubscribeCompleted = new EventEmitter<number>();

  constructor(
    private router: Router,
    private sqlServiceService: SqlServiceService,
  ){}

  navigateToPage(selectedSlpData: SlpProjectData) {
    this.router.navigate(['information'], { state: { savedSlpFormData: selectedSlpData } });
  }

  unsubscribe(slp : SlpProjectData){
    this.sqlServiceService.unsubscribeSLP(slp.SLPID).subscribe(() => {
      this.subscribedSlpData = this.subscribedSlpData.filter(x => x.SLPID !== slp.SLPID);
      this.unsubscribeCompleted.emit(slp.SLPID);
    });
  }
}
