import { SqlServiceService } from './core/sql-service.service';
import { Component } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/TokenService';
import { Roles } from './models/RoleID';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { lastValueFrom, Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [SqlServiceService]
})

export class AppComponent {
  title(title: any) {
    throw new Error('Method not implemented.');
  }
  username: any;
  profile: any;
  profileInitial: string;
  tabName = '';
  dataArray: any[] = [];
  setdisable: boolean = true;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    public sqlServiceService: SqlServiceService,
    public broadcastService: MsalBroadcastService,
    public msalService: MsalService,
    public authService: AuthService,
    private router: Router,
    public tokenService: TokenService
  ) { }

  ngOnInit() {
    this.initializeBroadcastListeners();

    if (environment.isE2E) {
      this.tokenService.startRefreshInterval();
    }

    this.setPermissions()
  }

  initializeBroadcastListeners() {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLogin();
      })

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.router.navigate(['/home'])
      });
  } 
  setPermissions() {
    this.authService.getPermissions().subscribe(res => {
      if (res != null) {
        localStorage.setItem('permissions', JSON.stringify(res));
      }
    });
  }

 getAccessTokenFromLocalStorage(): string {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.includes('accesstoken')) {
            const tokenData = localStorage.getItem(key);
            if (tokenData) {
                try {
                    const tokenObject = JSON.parse(tokenData);
                    if (tokenObject.target && tokenObject.target.includes('graph.microsoft.com')) {
                        console.warn("TOKEN "+  tokenObject.target);
                        return tokenObject.secret || "";  // Return the token if target matches
                    }
                } catch (error) {
                    console.error('Error parsing token data:', error);
                    return "";  // Return empty string in case of error
                }
            }
        }
    }
    return "";  // Return empty string if no matching token is found
}

  getTokenExpiryDate(token: string) : Date | null{
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
        console.error('Invalid token format');
        return null;
    }
    const payload = tokenParts[1];
    try {
        const decodedPayload = atob(payload);
        const payloadObject = JSON.parse(decodedPayload);
        if (payloadObject && payloadObject.exp) {
            const expiryDate = new Date(payloadObject.exp * 1000);
            return expiryDate;
        } else {
            console.error('Token does not contain an expiration field');
            return null;
        }
    } catch (error) {
        console.error('Error decoding token payload:', error);
        return null;
    }
  }

  getModelValuesFromApiResponse(
    model: { [key: string]: string },
    apiResponse: { value: string[] }
  ): string[] {
    return apiResponse.value.map((id) => model[id]).filter((value) => value !== undefined);
  }

  async setLogin() {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    await this.getTokenAndFetchRoles();
    await this.delay(100); 
    if (this.authService.hasAccessPermissions()) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/access-denied']);
    }
    this.username = this.authService.getAccount()?.username;
    this.profile = this.authService.getAccount()?.name;
    if (this.profile) {
      this.profileInitial = this.profile.split(',')[0].trim().charAt(0) + ' ' + this.profile.split(',')[1].trim().charAt(0);
    }
  }
  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

  async getTokenAndFetchRoles(){
    let bearertoken = this.getAccessTokenFromLocalStorage();
    const expiryDate = this.getTokenExpiryDate(bearertoken);

    if (expiryDate != null && this.isTokenExpired(expiryDate)) {
      bearertoken = await this.refreshToken();
    }
    let apiResponse = await lastValueFrom(this.sqlServiceService.getUserRoles(bearertoken));
    const matchedValues = this.getModelValuesFromApiResponse(Roles.keyValueMap, apiResponse);

    localStorage.setItem("LoggedInUserRoles",matchedValues.toString());
  }

  isTokenExpired(expiryDate: Date): boolean {
    const currentDate = new Date();
    return currentDate > expiryDate;
  }

  async refreshToken(): Promise<string> {
    const newToken = this.getAccessTokenFromLocalStorage();
    return newToken;
  }

  get authenticated(): boolean {
    return this.authService.getAccount() ? true : false;
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }

}